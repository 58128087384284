import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangelogEntry } from '../changelog-entry/changelog-entry';
import { ChangeLogService } from './changelog.service';

@Component({
  selector: 'onyx-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangeLogComponent {
  changelog: Observable<ChangelogEntry[]>;
  currentVersion$: Observable<string>;

  constructor(changelogService: ChangeLogService) {
    this.changelog = changelogService.changelog$;
    this.currentVersion$ = this.changelog.pipe(
      map(c => (c?.length > 0 ? c[0].version : ''))
    );
  }
}
