import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, merge, Subject } from 'rxjs';

import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { MicroFrontendsService } from './generated/services';

@Injectable({ providedIn: 'root' })
export class MicrofrontendTranslationService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private microFrontendsService: MicroFrontendsService
  ) {
    const allTranslationInfo$ = this.microFrontendsService
      .translations()
      .pipe(takeUntil(this.destroy$), shareReplay(1));
    const cachedTranslations = {};
    this.translate.onLangChange
      .pipe(
        switchMap(({ lang }) =>
          allTranslationInfo$.pipe(
            switchMap(allInfo => {
              const allObservables = allInfo.map(info => {
                const infoForLang = info.translations.find(
                  ti => ti.language === lang
                );
                if (!infoForLang) return EMPTY;
                if (
                  cachedTranslations[lang] &&
                  cachedTranslations[lang][info.name]
                ) {
                  return EMPTY;
                }
                return this.http.get(infoForLang.resourceUrl).pipe(
                  map(translations => ({
                    lang,
                    translations,
                    microfrontend: info.name
                  }))
                );
              });
              return merge(...allObservables);
            })
          )
        )
      )
      .subscribe(({ lang, translations, microfrontend }) => {
        const namespaced = {};
        namespaced[microfrontend] = translations;
        cachedTranslations[lang] = namespaced;
        this.translate.setTranslation(lang, namespaced, true);
      });
  }
  private destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
