import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClaimAuthorizationService } from './claim-authorization.service';
import { Claims } from './claims';

@Directive({
  selector: '[onyxRequiredClaims]'
})
export class ClaimRequiredDirective implements OnInit, OnDestroy {
  @Input() public onyxRequiredClaimOr = false;
  @Input() public onyxRequiredClaims: Claims[];
  private $onDestroy = new Subject();

  constructor(
    private claimAuthorizationService: ClaimAuthorizationService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<unknown>
  ) {}

  public ngOnInit() {
    this.claimAuthorizationService
      .userHasClaim(this.onyxRequiredClaimOr, this.onyxRequiredClaims)
      .pipe(takeUntil(this.$onDestroy))
      .subscribe(authorised => {
        if (authorised) {
          this.viewContainer.createEmbeddedView(this.template);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.$onDestroy.next(undefined);
    this.$onDestroy.complete();
  }
}
