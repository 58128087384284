import { Component, OnDestroy, Inject } from '@angular/core';
import { Subject, interval, Observable } from 'rxjs';
import {
  take,
  takeUntil,
  publishReplay,
  refCount,
  map,
  startWith
} from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ENVIRONMENT_TOKEN, Environment } from '@onyx/shared/common-imports';

@Component({
  template: `
    <h1 mat-dialog-title>A new version of the application is available.</h1>
    <div mat-dialog-content>
      Click <em>refresh</em> to reload the updated application. You can also
      click <em>ignore</em>, the update will be activated the next time you open
      the application.
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false">Ignore</button>
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial
      >
        Refresh (auto-refresh in {{ countdown$ | async }})
      </button>
    </div>
  `
})
export class UpdateModalComponent implements OnDestroy {
  private destroy$ = new Subject();
  public countdown$: Observable<number>;
  public constructor(
    dialogRef: MatDialogRef<UpdateModalComponent>,
    @Inject(ENVIRONMENT_TOKEN) environment: Environment
  ) {
    const autoRefreshDelay = environment.update_auto_refresh_delay || 30;
    this.countdown$ = interval(1000).pipe(
      map(x => x + 1),
      take(autoRefreshDelay + 1),
      startWith(0),
      map(x => autoRefreshDelay - x),
      publishReplay(),
      refCount(),
      takeUntil(this.destroy$)
    );
    this.countdown$.subscribe(x => {
      if (x === 0) {
        dialogRef.close(true);
      }
    });
  }
  public ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
