/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { StsDataAccessConfig } from '../sts-data-access-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthenticationScheme } from '../models/authentication-scheme';
import { TenantIdentityProvider } from '../models/tenant-identity-provider';

@Injectable({
  providedIn: 'root'
})
export class StsService extends BaseService {
  constructor(config: StsDataAccessConfig, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getProviders
   */
  static readonly GetProvidersPath = '/api/sts/Sts/providers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProviders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviders$Plain$Response(params?: {}): Observable<
    StrictHttpResponse<Array<AuthenticationScheme>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.GetProvidersPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AuthenticationScheme>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProviders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviders$Plain(params?: {}): Observable<Array<AuthenticationScheme>> {
    return this.getProviders$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<AuthenticationScheme>>) =>
          r.body as Array<AuthenticationScheme>
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProviders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviders$Json$Response(params?: {}): Observable<
    StrictHttpResponse<Array<AuthenticationScheme>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.GetProvidersPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<AuthenticationScheme>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProviders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviders$Json(params?: {}): Observable<Array<AuthenticationScheme>> {
    return this.getProviders$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<AuthenticationScheme>>) =>
          r.body as Array<AuthenticationScheme>
      )
    );
  }

  /**
   * Path part for operation getCurrentProvider
   */
  static readonly GetCurrentProviderPath = '/api/sts/Sts/provider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentProvider$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentProvider$Plain$Response(params?: {
    tenantKey?: string;
  }): Observable<StrictHttpResponse<TenantIdentityProvider>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.GetCurrentProviderPath,
      'get'
    );
    if (params) {
      rb.query('tenantKey', params.tenantKey);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantIdentityProvider>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentProvider$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentProvider$Plain(params?: {
    tenantKey?: string;
  }): Observable<TenantIdentityProvider> {
    return this.getCurrentProvider$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TenantIdentityProvider>) =>
          r.body as TenantIdentityProvider
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentProvider$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentProvider$Json$Response(params?: {
    tenantKey?: string;
  }): Observable<StrictHttpResponse<TenantIdentityProvider>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.GetCurrentProviderPath,
      'get'
    );
    if (params) {
      rb.query('tenantKey', params.tenantKey);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantIdentityProvider>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentProvider$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentProvider$Json(params?: {
    tenantKey?: string;
  }): Observable<TenantIdentityProvider> {
    return this.getCurrentProvider$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TenantIdentityProvider>) =>
          r.body as TenantIdentityProvider
      )
    );
  }

  /**
   * Path part for operation addProvider
   */
  static readonly AddProviderPath = '/api/sts/Sts/provider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProvider$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProvider$Json$Response(params?: {
    body?: TenantIdentityProvider;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.AddProviderPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addProvider$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProvider$Json(params?: {
    body?: TenantIdentityProvider;
  }): Observable<void> {
    return this.addProvider$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeSetupClient
   */
  static readonly RemoveSetupClientPath = '/api/sts/Sts/setup-client';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSetupClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSetupClient$Response(params?: {}): Observable<
    StrictHttpResponse<void>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      StsService.RemoveSetupClientPath,
      'delete'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeSetupClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSetupClient(params?: {}): Observable<void> {
    return this.removeSetupClient$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
