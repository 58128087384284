import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';

@Component({
  selector: 'ui-spinner',
  template: `
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  `,
  styles: [
    `
      :host {
        .spinner {
          margin: 100px auto;
          width: 40px;
          height: 40px;
          position: relative;
          text-align: center;

          -webkit-animation: sk-rotate 2s infinite linear;
          animation: sk-rotate 2s infinite linear;
        }

        .dot1,
        .dot2 {
          width: 60%;
          height: 60%;
          display: inline-block;
          position: absolute;
          top: 0;

          border-radius: 100%;

          -webkit-animation: sk-bounce 2s infinite ease-in-out;
          animation: sk-bounce 2s infinite ease-in-out;
        }

        .dot2 {
          top: auto;
          bottom: 0;
          -webkit-animation-delay: -1s;
          animation-delay: -1s;
        }
      }

      @keyframes sk-rotate {
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes sk-bounce {
        0%,
        100% {
          transform: scale(0);
          -webkit-transform: scale(0);
        }
        50% {
          transform: scale(1);
          -webkit-transform: scale(1);
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {}

@NgModule({
  imports: [],
  exports: [SpinnerComponent],
  declarations: [SpinnerComponent],
  providers: []
})
export class SpinnerComponentModule {}
