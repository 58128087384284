import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ClaimAuthorizationService } from '../claim-authorization.service';
import { Claims } from '../claims';
import { ClaimEnabledButtonDirective } from './claim-enabled-button-directive';

@Component({
  selector: 'onyx-claim-enabled',
  template: `
    <div
      class="claim-enabled-wrapper"
      [attr.disabled]="(isAuthorized$ | async) === false"
      [matTooltip]="(isAuthorized$ | async) ? '' : getToolTipMessage()"
      [matTooltipDisabled]="isAuthorized$ | async"
    >
      <ng-content></ng-content>
    </div>
  `
})
export class ClaimEnabledComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @Input() public requiredClaimOr = false;
  @Input() public requiredClaims: Claims[];
  private onDestroy$ = new Subject();
  public isAuthorized$: Observable<boolean>;

  @ContentChildren(ClaimEnabledButtonDirective, { descendants: true })
  contentButtons!: QueryList<ClaimEnabledButtonDirective>;

  constructor(
    private claimAuthorizationService: ClaimAuthorizationService,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.isAuthorized$ = this.claimAuthorizationService
      .userHasClaim(this.requiredClaimOr, this.requiredClaims)
      .pipe(takeUntil(this.onDestroy$));
  }

  public getToolTipMessage() {
    return this.translate.instant('unauthorized.permissionRequired', {
      0: this.getTranslatedClaims()
    });
  }

  ngAfterContentInit() {
    this.isAuthorized$.subscribe(authorized => {
      //timeout to queue macrotask https://angular.io/errors/NG0100
      if (!authorized) setTimeout(() => this.disableContent(), 0);
    });
  }

  private disableContent(): void {
    this.contentButtons.forEach(button => {
      button.setDisabled();
    });
  }

  private getTranslatedClaims(): string {
    const translatedClaims = [];
    this.requiredClaims.forEach(claim => {
      const key = this.getClaimsTranslationKey(claim);
      translatedClaims.push(this.translate.instant(key));
    });
    return translatedClaims.join(', ');
  }

  private getClaimsTranslationKey(claim: Claims) {
    if (Claims.OffboardVehicleActions === claim) {
      return `unauthorized.permissions.${claim}.other`;
    }
    return `unauthorized.permissions.${claim}`;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
