import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Setting } from './settings';
import { SUPPORTED_CULTURES } from '@onyx/shared/common';

@Injectable({
  providedIn: 'root'
})
export class InitialSettingsLoader {
  public constructor(private translate: TranslateService) {}

  public getInitialSettings() {
    const initialSettings = INITIAL_SETTINGS;

    const languageSetting = initialSettings.find(
      setting => setting.key === 'language'
    );
    const uiLanguageSetting = languageSetting.children.find(
      setting => setting.key === 'ui-language'
    );
    this.setLanguageOptions(uiLanguageSetting);

    return initialSettings;
  }

  private setLanguageOptions(uiLanguage: Setting) {
    uiLanguage.options = [];
    Object.keys(SUPPORTED_CULTURES).forEach(lang => {
      uiLanguage.options.push({
        value: lang,
        label: SUPPORTED_CULTURES[lang].fullName
      });
    });
  }
}

export const INITIAL_SETTINGS: Setting[] = [
  {
    key: 'appearance',
    feature: '',
    label: marker('settings.labels.appearance'),
    children: [
      {
        key: 'dark_theme',
        feature: '',
        label: marker('settings.labels.darkTheme'),
        value: false
      }
    ]
  },
  {
    key: 'language',
    feature: 'multi-language',
    label: marker('settings.labels.language'),
    children: [
      {
        key: 'ui-language',
        feature: 'multi-language',
        label: marker('settings.labels.uiLanguage'),
        value: 'en'
      }
    ]
  }
];
