<section class="entry">
  <span class="entry-date">{{ entry?.date }}</span>
  <h3>{{ entry?.title }}</h3>
  <div [class.limitTextHeight]="isReadMore">
    <h4>{{ 'settings.changelog.features' | translate }}</h4>
    <ul class="feature-list">
      <li *ngFor="let feature of entry?.features">
        <mat-icon svgIcon="icon_feature"></mat-icon>
        <span>{{ feature.title }}</span>
      </li>
    </ul>
    <h4 *ngIf="entry?.bugfixes?.length > 0" data-testid="bugfix-title">
      {{ 'settings.changelog.bugfixes' | translate }}
    </h4>
    <ul class="bugfix-list">
      <li *ngFor="let bugfix of entry?.bugfixes">
        <mat-icon svgIcon="icon_bugfixes"></mat-icon>
        <span>{{ bugfix.title }}</span>
      </li>
    </ul>
  </div>
  <a (click)="showText()" class="readmore">
    {{
      isReadMore
        ? ('settings.changelog.readmore' | translate)
        : ('settings.changelog.readless' | translate)
    }}
  </a>
</section>
