import { Injectable } from '@angular/core';
import {
  EventTypes,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import { filter, map } from 'rxjs/operators';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { OnyxEventService } from '../onyx-event-service';
import { OnyxEvents } from '../onyx-events';

@Injectable()
export class OidcTokenEventService implements OnyxEventService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private eventService: CustomComponentEventsService,
    private authEventService: PublicEventsService
  ) {}

  initialize() {
    const newToken$ = this.authEventService.registerForEvents().pipe(
      filter(e => e.type === EventTypes.NewAuthenticationResult),
      map(() => this.oidcSecurityService.getAccessToken())
    );
    this.eventService
      .onMicroFrontendLoaded(newToken$)
      .subscribe(token =>
        this.eventService.publishEvent(OnyxEvents.AuthToken, token)
      );
  }
}
