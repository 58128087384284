import { LayoutModule } from '@angular/cdk/layout';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SpinnerComponentModule } from '@onyx-ui';
import { IconsModule } from '@onyx/core';
import { NatsModule as LocalNatsModule, NatsSubject } from '@onyx/nats';
import { NavigationModule } from '@onyx/navigation';
import {
  AccessTokenProvider,
  AccessTokenProviderToken,
  NatsModule
} from '@onyx/ngx-nats';
import { AuthorizationHttpInterceptor } from '@onyx/shared/authentication';
import { CustomMatPaginatorIntl, OnyxCommonModule } from '@onyx/shared/common';
import {
  CommonImportsModule,
  ENVIRONMENT_TOKEN
} from '@onyx/shared/common-imports';
import { SharedLoggingModule } from '@onyx/shared/logging';
import { MicroFrontendsModule } from '@onyx/shared/micro-frontends';
import { UpdateModule } from '@onyx/shared/update';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { AppRoutesModule } from './app-routes.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication.module';
import { JobPanelsModule } from './job-panels/job-panels.module';
import { NoTenantsComponent } from './no-tenants.component';
import { NotFoundComponent } from './not-found.component';
import { OnyxErrorHandler } from './onyx.error-handler';
import { PanelModule } from './panel/panel.module';
import { TranslationsModule } from './translations/translations.module';

const natsUrl = environment.production // production is behind reverse proxy, development is not
  ? `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${
      window.location.host
    }/socket`
  : environment.nats_uri;

@NgModule({
  declarations: [AppComponent, NotFoundComponent, NoTenantsComponent],
  imports: [
    BrowserModule,
    AppRoutesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthenticationModule,
    SharedLoggingModule,
    CommonImportsModule,
    OnyxCommonModule,
    LocalNatsModule,
    LayoutModule,
    NavigationModule,
    IconsModule,
    UpdateModule,
    NatsModule.forRoot({
      useAuthentication: environment.useAuthentication,
      reconnect: true,
      maxReconnectAttempts: -1,
      debug: false,
      heartbeatTimeout: 12_000,
      servers: natsUrl,
      fleetManagerHeartBeatSubject: NatsSubject.FleetManagerStatus,
      accessTokenProvider: {
        provide: AccessTokenProviderToken,
        useFactory: (oidcService: OidcSecurityService) =>
          ({
            getAccessToken: () => oidcService.getAccessToken()
          } as AccessTokenProvider),
        deps: [OidcSecurityService]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    MicroFrontendsModule.withOffboardHooks(),
    TranslationsModule,
    SpinnerComponentModule,
    PanelModule,
    JobPanelsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHttpInterceptor,
      multi: true
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment
    },
    { provide: ErrorHandler, useClass: OnyxErrorHandler },
    {
      provide: APP_BASE_HREF,
      useValue: ''
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }
  ]
})
export class AppModule {}
