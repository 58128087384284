import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnChanges
} from '@angular/core';

//https://stackblitz.com/edit/angular-search-text-highlight-directive?file=src%2Fapp%2Fapp.component.html
@Directive({ selector: '[uiHighlight]' })
export class HighlightDirective implements OnChanges {
  constructor(private el: ElementRef) {}

  @Input()
  set content(content: string) {
    this._content = '' + content;
  }
  get content(): string {
    return this._content;
  }

  @Input()
  set searchTerm(searchTerm: string) {
    //trim the search and replace spaces with |
    //wich matches each word in the string
    //ex: this|is|match
    //would match all instances of the string "this" "is" and "match"
    //regardless of how many times they occur
    this._searchTerm = searchTerm?.trim()?.replace(/\s/g, '|') ?? '';
    this.matchpattern = new RegExp(this._searchTerm, 'gi');
    this.searchPattern = new RegExp(this._searchTerm, 'i');
  }
  get searchTerm(): string {
    return this._searchTerm;
  }

  private _content: string;
  private _searchTerm: string;

  spanStart = '<span class="highlighted">';
  spanEnd = '</span>';
  searchPattern: RegExp;
  matchpattern: RegExp;

  private separatedText = [];
  private separatedSearchedText = [];
  private final = '';

  ngOnChanges(): void {
    this.highlight();
  }

  highlight() {
    this.final = '';
    if (
      this._searchTerm !== undefined &&
      this._searchTerm != null &&
      this._searchTerm.length > 0 &&
      this._searchTerm[0] !== '.'
    ) {
      this.separatedText = this._content.split(this.searchPattern);
      this.separatedSearchedText = this._content.match(this.matchpattern);

      if (
        this.separatedSearchedText != null &&
        this.separatedSearchedText.length > 0
      ) {
        for (let i = 0; i < this.separatedText.length; i++) {
          if (i <= this.separatedSearchedText.length - 1) {
            this.final +=
              this.separatedText[i] +
              this.spanStart +
              this.separatedSearchedText[i] +
              this.spanEnd;
          } else {
            this.final += this.separatedText[i];
          }
        }
      }
      if (this.final.length > 0) {
        this.el.nativeElement.innerHTML = this.final;
      } else {
        this.el.nativeElement.innerText = this._content;
      }
    } else {
      this.el.nativeElement.innerText = this._content;
    }
  }
}

@NgModule({
  imports: [],
  exports: [HighlightDirective],
  declarations: [HighlightDirective],
  providers: []
})
export class HighlightDirectiveModule {}
