import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { OnyxEvents } from '../onyx-events';

@Injectable()
export class SupportedLanguagesEventService {
  constructor(
    private eventService: CustomComponentEventsService,
    private translate: TranslateService
  ) {}
  initialize(): void {
    this.eventService
      .onMicroFrontendLoaded(of(this.translate.getLangs()))
      .subscribe(languages =>
        this.eventService.publishEvent(OnyxEvents.SupportedLanguages, languages)
      );
  }
}
