import { TById } from './tById';

export interface WithId {
  id?: string;
}

export const mapToDictionary = <T extends WithId>(entities: T[]) =>
  entities.reduce((acc, val) => {
    if (val.id) {
      acc[val.id] = val;
    }
    return acc;
  }, {} as TById<T>);
