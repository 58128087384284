import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OidcSecurityServiceMock } from './mocks/oidc-security-service.mock';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    {
      provide: OidcSecurityService,
      useClass: OidcSecurityServiceMock
    }
  ]
})
export class SharedAuthenticationTestingModule {}
