import { chrome, safari, edge, firefox } from 'is_js';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserSupportService {
  public constructor() {}

  public isSupported() {
    return chrome() || safari() || edge() || firefox();
  }
}
