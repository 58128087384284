import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, Observable, Subject, take, takeUntil, timer } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
  selector: 'onyx-nats-reboot-popup',
  templateUrl: './nats-reboot-popup.component.html',
  styleUrls: []
})
export class NatsRebootPopupComponent implements OnDestroy, OnInit {
  public countdown$: Observable<number>;

  public countDownTranslation$: Observable<{ countDown: string }>;
  private destroy$ = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<NatsRebootPopupComponent>) {
    const autoRefreshDelay = 5;

    this.countdown$ = timer(0, 1000).pipe(
      map(x => autoRefreshDelay - x),
      take(autoRefreshDelay + 1),
      takeUntil(this.destroy$),
      share()
    );
    this.countDownTranslation$ = this.countdown$.pipe(
      map(c => ({ countDown: c + '' }))
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.countdown$.subscribe(x => {
      if (x === 0) this.dialogRef.close(true);
    });
  }
}
