<mat-card class="profile-popup" *ngIf="userData$ | async; let profile">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon svgIcon="icon_user"></mat-icon>
    </div>
    <mat-card-title>{{ profile.name }}</mat-card-title>
    <mat-card-subtitle><!--Role(s)-->&nbsp;</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div>
      <h3>{{ 'profile.permissions' | translate }}</h3>
      <ul>
        <li *ngFor="let permission of claimsInProfile(profile)">
          {{ permission | translate }}
        </li>
      </ul>
    </div>
    <div class="button-group">
      <button mat-flat-button color="primary" (click)="signOut()">
        {{ 'profile.logout' | translate }}
      </button>
      <span
        [matTooltip]="'profile.disabled.externalAuth' | translate"
        [matTooltipDisabled]="!isExternallyAuthenticated"
      >
        <button
          mat-flat-button
          color="secondary"
          (click)="changePassword()"
          [disabled]="isExternallyAuthenticated"
        >
          {{ 'profile.changePassword' | translate }}
        </button>
      </span>
    </div>
  </mat-card-content>
</mat-card>
