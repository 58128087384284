/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MainMenuOptions } from '../models/main-menu-options';
import { MicroFrontend } from '../models/micro-frontend';
import { MicroFrontendClientConfig } from '../models/micro-frontend-client-config';
import { SystemOverviewPanelOptions } from '../models/system-overview-panel-options';
import { TranslationsForMicroFrontends } from '../models/translations-for-micro-frontends';

@Injectable({
  providedIn: 'root'
})
export class MicroFrontendsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation ready
   */
  static readonly ReadyPath = '/api/micro-frontends/ready';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ready()` instead.
   *
   * This method doesn't expect any request body.
   */
  ready$Response(params?: {}): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.ReadyPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ready$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ready(params?: {}): Observable<void> {
    return this.ready$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation live
   */
  static readonly LivePath = '/api/micro-frontends/live';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `live()` instead.
   *
   * This method doesn't expect any request body.
   */
  live$Response(params?: {}): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.LivePath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `live$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  live(params?: {}): Observable<void> {
    return this.live$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/api/micro-frontends';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MicroFrontend>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.GetPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MicroFrontend>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: {}): Observable<Array<MicroFrontend>> {
    return this.get$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MicroFrontend>>) =>
          r.body as Array<MicroFrontend>
      )
    );
  }

  /**
   * Path part for operation menuItems
   */
  static readonly MenuItemsPath = '/api/micro-frontends/menu-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `menuItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  menuItems$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MainMenuOptions>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.MenuItemsPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MainMenuOptions>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `menuItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  menuItems(params?: {}): Observable<Array<MainMenuOptions>> {
    return this.menuItems$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MainMenuOptions>>) =>
          r.body as Array<MainMenuOptions>
      )
    );
  }

  /**
   * Path part for operation translations
   */
  static readonly TranslationsPath = '/api/micro-frontends/translations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translations()` instead.
   *
   * This method doesn't expect any request body.
   */
  translations$Response(params?: {}): Observable<
    StrictHttpResponse<Array<TranslationsForMicroFrontends>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.TranslationsPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TranslationsForMicroFrontends>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `translations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translations(params?: {}): Observable<Array<TranslationsForMicroFrontends>> {
    return this.translations$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<TranslationsForMicroFrontends>>) =>
          r.body as Array<TranslationsForMicroFrontends>
      )
    );
  }

  /**
   * Path part for operation mainMenuBundle
   */
  static readonly MainMenuBundlePath =
    '/api/micro-frontends/menu-items/{id}/bundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mainMenuBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  mainMenuBundle$Response(params: {
    id: null | string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.MainMenuBundlePath,
      'get'
    );
    if (params) {
      rb.path('id', params.id);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mainMenuBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mainMenuBundle(params: { id: null | string }): Observable<string> {
    return this.mainMenuBundle$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation clientConfig
   */
  static readonly ClientConfigPath = '/api/micro-frontends/client-config/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientConfig$Response(params: {
    id: null | string;
  }): Observable<StrictHttpResponse<MicroFrontendClientConfig>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.ClientConfigPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<MicroFrontendClientConfig>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientConfig(params: {
    id: null | string;
  }): Observable<MicroFrontendClientConfig> {
    return this.clientConfig$Response(params).pipe(
      map(
        (r: StrictHttpResponse<MicroFrontendClientConfig>) =>
          r.body as MicroFrontendClientConfig
      )
    );
  }

  /**
   * Path part for operation systemOverviewPanels
   */
  static readonly SystemOverviewPanelsPath =
    '/api/micro-frontends/system-overview-panels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemOverviewPanels()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemOverviewPanels$Response(params?: {}): Observable<
    StrictHttpResponse<Array<SystemOverviewPanelOptions>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.SystemOverviewPanelsPath,
      'get'
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SystemOverviewPanelOptions>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemOverviewPanels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemOverviewPanels(params?: {}): Observable<
    Array<SystemOverviewPanelOptions>
  > {
    return this.systemOverviewPanels$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<SystemOverviewPanelOptions>>) =>
          r.body as Array<SystemOverviewPanelOptions>
      )
    );
  }

  /**
   * Path part for operation systemOverviewPanelBundle
   */
  static readonly SystemOverviewPanelBundlePath =
    '/api/micro-frontends/system-overview-panels/{id}/bundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemOverviewPanelBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemOverviewPanelBundle$Response(params: {
    id: null | string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MicroFrontendsService.SystemOverviewPanelBundlePath,
      'get'
    );
    if (params) {
      rb.path('id', params.id);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `systemOverviewPanelBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemOverviewPanelBundle(params: { id: null | string }): Observable<string> {
    return this.systemOverviewPanelBundle$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }
}
