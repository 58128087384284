import { Injectable } from '@angular/core';
import { FeatureOverlayRef } from './feature-overlay/feature-overlay.ref';

@Injectable()
export class FeatureOverlayServiceMock {
  public constructor() {}

  public openFeaturesOverlay(): FeatureOverlayRef {
    return {} as FeatureOverlayRef;
  }
}
