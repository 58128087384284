/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';

export interface Environment {
  production: boolean;
  oidc_authority: string;
  oidc_clientid: string;
  oidc_postlogin_path: string;
  oidc_postlogout_path: string;
  oidc_scope: string;
  oidc_responsetype: string;
  tracer_service_name: string;
  jaeger_span_endpoint: string;
  update_frequency: number; // check for an update of the client every minute
  update_auto_refresh_delay: number; // give the user half a minute to respond to the update modal
  useAuthentication: boolean;
  nats_uri: string; // development purposes normally
  brand: ThemeKey;
}

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>(
  'ENVIRONMENT_TOKEN'
);

export type ThemeKey = 'onyx' | 'kion' | 'dematic' | 'linde' | 'still';
