import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMaterialModule } from '@onyx/shared/common-imports';
import { NatsRebootListenerService } from './nats-reboot-listener.service';
import { NatsRebootPopupComponent } from './nats-reboot-popup/nats-reboot-popup.component';
import { NatsSubject } from './nats-subject';

export * from './helpers/helpers';
export * from './helpers/tById';
export * from './nats.ws';
export { NatsSubject, NatsRebootListenerService };

@NgModule({
  imports: [CommonModule, CustomMaterialModule, TranslateModule],
  exports: [],
  declarations: [NatsRebootPopupComponent],
  providers: []
})
export class NatsModule {}
