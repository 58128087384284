import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedAuthenticationModule } from '@onyx/shared/authentication';
import {
  AuthModule,
  EventTypes,
  LogLevel,
  PublicEventsService
} from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedAuthenticationModule,
    TranslateModule,
    AuthModule.forRoot({
      config: {
        //explicitly use configId because it otherwise can't find a config in the authorization-http-interceptor.ts
        configId: 'oidc',
        authority:
          environment.oidc_authority === '.'
            ? window.location.origin
            : environment.oidc_authority,
        redirectUrl: `${window.location.origin}/${environment.oidc_postlogin_path}`,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.oidc_clientid,
        scope: environment.oidc_scope,
        responseType: environment.oidc_responsetype,
        autoUserInfo: true,
        silentRenew: true,
        //renew the access token before the embedded nats token gets invalidated to mitigate race conditions
        renewTimeBeforeTokenExpiresInSeconds: 5,
        useRefreshToken: true,
        logLevel: LogLevel.Warn,
        historyCleanupOff: false
      }
    })
  ],
  exports: [AuthModule, SharedAuthenticationModule],
  providers: []
})
export class AuthenticationModule {
  constructor(private eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe(config => {
        console.log('loaded config', config.value);
      });
  }
}
