import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ui-details-drawer-row',
  template: `
      <div class="property">
        <ng-content></ng-content>
      </div>   
  `,
  styles: [
    `
    .property {
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      padding-bottom: 12px;
      margin-bottom:16px;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
    }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsDrawerRowComponent {
}
