import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Feature } from './feature';

@Injectable()
export class FeatureDummyService {
  public readonly allExperimentalFeatures$: Observable<Feature[]> = new Subject<
    Feature[]
  >();
  public readonly allEnabledFeatureKeys$: Observable<string[]> = new Subject<
    string[]
  >();

  constructor() {}

  public hasFeatureEnabled(): Observable<boolean> {
    return of(false);
  }

  public async setExperimentalFeatureState() {}
}
