import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FeatureDirective } from './feature.directive';
import { FeatureOverlayComponent } from './feature-overlay/feature-overlay.component';
import { FeatureListItemComponent } from './feature-list-item/feature-list-item.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonImportsModule } from '@onyx/shared/common-imports';
import { FeatureOverlayService } from './feature-overlay.service';
import { LayoutModule } from '@angular/cdk/layout';
import { FeatureOverlayRef } from './feature-overlay/feature-overlay.ref';
import { FeatureService } from './feature.service';

export { FeatureOverlayService, FeatureOverlayRef, FeatureService };

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    OverlayModule,
    LayoutModule,
    CommonImportsModule
  ],
  exports: [FeatureDirective],
  declarations: [
    FeatureDirective,
    FeatureOverlayComponent,
    FeatureListItemComponent
  ],
  providers: [FeatureService]
})
export class SharedFeatureFlagsModule {}
