/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { OverrideColumnDirective } from './override-column.directive';

/**
 * Object containing translated labels for when the table is empty
 */
export interface EmptyReasonLabels {
  /**
   * empty reason: The table is still loading data
   */
  loading: string;
  /**
   * empty reason: No data matches the filter
   */
  emptyFilter: string;
  /**
   * empty reason: The data is loaded, but empty
   */
  noData: string;
}

export type TableStructure<T> = {
  [k in keyof T | 'select' | 'state']?: string;
};

@Component({
  selector: 'ui-table',
  templateUrl: './table.component.html',
  styles: [
    `
      :host {
        mat-table {
          mat-row {
            mat-cell {
              height: 37px;
              &:first-child {
                padding-left: 20px;
              }
              &.mat-column-select {
                flex-grow: 0;
                min-width: 64px;
                justify-content: center;
              }
              &:nth-child(2) {
                padding-left: 30px;
              }
            }
            &:last-child {
              border-radius: 0 0 0 8px;
            }
          }
          mat-header-row {
            mat-header-cell {
              &.mat-column-id {
                padding-left: 30px;
              }
              &.mat-column-select {
                flex-grow: 0;
                min-width: 64px;
                justify-content: center;
              }
            }
          }

          mat-checkbox {
            box-sizing: border-box;
            width: 24px;
            height: 24px;
            span.mat-ripple {
              display: none;
            }
          }
        }

        .under-table {
          display: flex;
          justify-content: flex-end;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T> implements AfterViewInit, AfterContentInit {
  private _elements: T[] = [];
  dataSource = new MatTableDataSource<T>();

  /**
   * The key of the object to use as the unique identifier for each row.
   */
  @Input() key?: keyof T;

  /**
   * When the key is not used in the table, you need to override the default sorting
   * with another column
   */
  @Input() defaultSortActive?: string;
  @Input() defaultSortDirection: 'asc' | 'desc' = 'asc';

  /**
   * The elements to display in the table.
   */
  @Input()
  set elements(value: T[] | null) {
    if (value === null) return;
    this._elements = value;
    this.dataSource.data = this._elements;

    if (!this.dataSource.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    }

    this.setSelectedElements();
  }
  get elements(): T[] {
    return this._elements;
  }

  get anythingSelected() {
    return this.selection.hasValue();
  }

  /**
   * The columns to display in the table where the key is the column name and the value is the label in the header.
   * "select" is a required column since we're always rendering it.
   */
  @Input() structure: {
    [k in keyof T]?: string;
  } = {};

  /**
   * Labels for when the button is empty
   */
  @Input() emptyReasonLabels: EmptyReasonLabels = {
    emptyFilter: '',
    loading: '',
    noData: ''
  };
  /**
   * Translated label for the filter input
   */
  @Input() filterLabel = '';
  /**
   * Translated label for the select all button
   */
  @Input() selectAllLabel = '';
  /**
   * Translated label for the deselect all button
   */
  @Input() deselectLabel = '';

  /**
   * A list of elements that are currently selected in the table.
   * The key Input is used to determine if an element equals another element.
   */
  @Input()
  set selectedElements(selectedElements: T[]) {
    this._selectedElements = selectedElements;
    this.setSelectedElements();
  }

  /**
   * When the selection changes due to human interaction (ie clicking a row or checkbox) the selectionChange event is emitted with the new selection.
   */
  @Output() selectedElementsChanges = new EventEmitter<T[]>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /**
   * Structural directivef used to override columns based on the structure property name.
   */
  @ContentChildren(OverrideColumnDirective)
  overrideColumns!: QueryList<OverrideColumnDirective>;

  private _overrideColumnsById: { [k: string]: OverrideColumnDirective } = {};

  private _selectedElements: T[] = [];

  searchFieldControl = new FormControl('');

  //deliberate use of any! otherwise it becomes very hard to track what is selected genericly
  selection = new SelectionModel<any>(true, []);

  private destroy$ = new Subject<void>();
  currentFilter = '';

  /**
   * function to override classes on certain rows (for example adding a disabled class if the entity is disabled)
   * @returns The classes to render on a row based on the row being rendered
   */
  @Input() rowClassSelector: (row: T) => string | null = () => null;

  get columns() {
    if (this.structure) {
      return Object.keys(this.structure).filter(c => c !== 'select');
    }
    return [];
  }

  get allColumns() {
    return [...this.columns, 'select'];
  }

  hasOverride(column: string) {
    return this._overrideColumnsById[column];
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.searchFieldControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(50))
      .subscribe((value: string) => {
        this.currentFilter = value;
        this.dataSource.filter = value.trim().toLowerCase();
      });

    this.dataSource.filterPredicate = this.filterData.bind(this);
  }
  private filterData(data: T, filter: string) {
    const filteredKeys = Object.keys(data).filter(k =>
      this.columns.some(c => c === k)
    );

    const filteredDataObject: { [key: string]: string } = {};
    for (const key of filteredKeys) {
      filteredDataObject[key] = (data as any)[key].toString();
    }
    const sanitizedData = Object.values(filteredDataObject)
      .join('◬') // stolen from the default implementation
      .toLowerCase();

    const sanitizedFilters = filter.trim().toLowerCase().split(' ');
    return sanitizedFilters.every(f => sanitizedData.includes(f));
  }
  ngAfterContentInit(): void {
    this.overrideColumns.forEach(c => {
      this._overrideColumnsById[c.uiOverrideColumn] = c;
    });
  }

  trackBy: TrackByFunction<T> = (_index: number, element: T) =>
    this.key ? element[this.key] : _index;

  singleSelect(row: T) {
    if (!this.key) return;
    const wasSelected = this.selection.isSelected(row[this.key]);
    const isMultiSelect = this.selection.selected.length > 1;

    if (isMultiSelect && wasSelected) {
      this.selection.clear();
      this.selection.select(row[this.key]);
      this.selectedElementsChanges.emit([row]);
      return;
    }
    if (wasSelected) {
      this.selection.clear();
      this.selectedElementsChanges.emit([]);
      return;
    }
    this.selection.select(row[this.key]);
    this.selectedElementsChanges.emit([row]);
  }

  toggleSelection(row: T) {
    if (!this.key) return;
    this.selection.toggle(row[this.key]);

    const to = this.selection.selected
      .map(id => this.elements.find((e: any) => e[this.key] === id))
      .filter(e => !!e) as T[];
    this.selectedElementsChanges.emit(to);
  }

  emptyListReason(): string {
    if (!this.elements) return this.emptyReasonLabels.loading;
    if (this.currentFilter !== '') return this.emptyReasonLabels.emptyFilter;
    return this.emptyReasonLabels.noData;
  }

  public deselectAll() {
    this.selection.clear();
    this.selectedElementsChanges.emit([]);
  }

  public async selectAll() {
    if (!this.key) return;
    this.selectedElementsChanges.emit(this.dataSource.filteredData);
  }

  private setSelectedElements() {
    if (!this.key) return;
    this.selection.clear();
    if (!this._selectedElements) return;
    const selected = this.dataSource.data?.filter(l =>
      this._selectedElements.find(s => s[this.key!] === l[this.key!])
    );
    this.selection.select(...selected.map(s => s[this.key!]));
  }

  private sortingDataAccessor(data: T, sortHeaderId: string) {
    if (sortHeaderId === 'select') {
      return this.selection.isSelected(data[this.key!]) ? 1 : 0;
    }
    const override = this.hasOverride(sortHeaderId);
    if (override && override.uiOverrideColumnOverrideSortHeader) {
      return data[override.uiOverrideColumnOverrideSortHeader];
    }
    return data[sortHeaderId];
  }
}
