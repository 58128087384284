import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NatsConnectionMonitor, NatsConnectionState } from '@onyx/ngx-nats';
import { Observable } from 'rxjs';

@Component({
  selector: 'onyx-connection-snack-bar',
  templateUrl: './connection-snack-bar.component.html',
  styleUrls: ['./connection-snack-bar.component.scss']
})
export class ConnectionSnackBarComponent {
  public connections$: Observable<NatsConnectionState[]>;
  public constructor(
    natsConnectionMonitor: NatsConnectionMonitor,
    private translate: TranslateService
  ) {
    this.connections$ = natsConnectionMonitor.activeConnections$;
  }

  public getLabel(key: string) {
    switch (key) {
      case 'Nats':
        return this.translate.instant('connection.key.nats');
      case 'Fleetmanager':
        return this.translate.instant('connection.key.fleetmanager');
      default:
        return key;
    }
  }
}
