import { Directive, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[onyxLanguage]'
})
export class LanguageAttributeDirective implements OnDestroy {
  private _onDestroy$ = new Subject();
  private _addToHtmlTag$ = new Subject<boolean>();

  @Input()
  set onyxLanguage(addToHtmlTag: boolean) {
    addToHtmlTag = typeof addToHtmlTag === 'boolean' ? addToHtmlTag : true;
    this._addToHtmlTag$.next(addToHtmlTag);
  }

  public constructor(
    translate: TranslateService,
    element: ElementRef,
    @Inject(DOCUMENT) document: Document
  ) {
    const currentLanguage$ = translate.onLangChange.pipe(
      map(x => x.lang),
      startWith(translate.currentLang)
    );
    combineLatest([currentLanguage$, this._addToHtmlTag$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([language, addToHtmlTag]) => {
        (element.nativeElement as HTMLElement).setAttribute('lang', language);
        if (addToHtmlTag) {
          document.documentElement.setAttribute('lang', language);
        }
      });
  }

  public ngOnDestroy() {
    this._onDestroy$.next(undefined);
    this._onDestroy$.complete();
  }
}
