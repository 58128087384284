import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onyxHightlight'
})
export class HighLightPipe implements PipeTransform {
  transform(value: string, search: string): string {
    let valueStr = value + '';
    const sanitizedSearch = search.trim().split(' ');
    sanitizedSearch.forEach(s => {
      valueStr = valueStr.replace(
        //examples
        //Should not match on html tags
        //for string <div>div</div>
        //matches div (not <div> or </div>)
        //Should not match on html characters
        //for string &lt;
        //does not match search term 'lt'
        new RegExp(
          '(?![^&;]+;)(?!<[^<>]*)(' + s + ')(?![^<>]*>)(?![^&;]+;)',
          'gi'
        ),
        '<span class="highlighted">$1</span>'
      );
    });
    return valueStr;
  }
}
