import {
  Component,
  Input,
  EventEmitter,
  Output,
  HostListener
} from '@angular/core';
import { Feature } from '../feature';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'onyx-feature-list-item',
  templateUrl: './feature-list-item.component.html',
  styleUrls: ['./feature-list-item.component.scss']
})
export class FeatureListItemComponent {
  @Input() public feature: Feature;
  @Input() public enabled: boolean;
  @Output() public enabledChange = new EventEmitter<boolean>();

  @HostListener('click')
  public onClick() {
    this.enabledChange.emit(!this.enabled);
  }

  public onToggle(event: MatSlideToggleChange) {
    this.enabledChange.emit(event.checked);
  }

  public click(_event: Event) {}
}
