import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { Observable, ReplaySubject, share } from 'rxjs';

export const LAST_VISITED_AGV_STORAGE_KEY = 'last-visisted-agv';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {
  public lastVisitedAgv$: Observable<string>;

  public constructor(router: Router, activatedRoute: ActivatedRoute) {
    const currentVisitedAgv$ = router.events.pipe(
      filter<ActivationStart>(x => x instanceof ActivationStart),
      filter(x => x.snapshot.data['isAgvDisplay']),
      map(x => x.snapshot.paramMap.get('id')),
      startWith(localStorage.getItem(LAST_VISITED_AGV_STORAGE_KEY))
    );
    const activeRouteIsNotAgvDisplay$ = router.events.pipe(
      filter<ActivationStart>(x => x instanceof ActivationStart),
      filter(x => !!x.snapshot.component),
      map(x => x.snapshot.data['isAgvDisplay']),
      startWith(
        activatedRoute.firstChild &&
          activatedRoute.firstChild.firstChild &&
          activatedRoute.firstChild.firstChild.snapshot.data['isAgvDisplay']
      ),
      map(x => !x)
    );
    this.lastVisitedAgv$ = activeRouteIsNotAgvDisplay$.pipe(
      withLatestFrom(currentVisitedAgv$),
      map(([activeRouteIsNotAgvDisplay, currentVisitedAgv]) =>
        activeRouteIsNotAgvDisplay ? currentVisitedAgv : null
      ),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false
      })
    );
  }
}
