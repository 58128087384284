import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ChangelogEntry } from '../changelog-entry/changelog-entry';

@Injectable({ providedIn: 'root' })
export class ChangeLogService {
  changelogUri = '/assets/changelog/changelog.json';
  constructor() {
    this.changelog$ = from(fetch(this.changelogUri)).pipe(
      switchMap(r => from(r.json())),
      map(r => r as ChangelogEntry[]),
      shareReplay(1)
    );
  }

  changelog$: Observable<ChangelogEntry[]>;
}
