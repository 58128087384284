import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class LocalStorageServiceMock {
  private storageChanged: { [index: string]: ReplaySubject<string> } = {};

  public listenForChanges(key: string) {
    if (!this.storageChanged[key])
      this.storageChanged[key] = new ReplaySubject(1);
    return this.storageChanged[key].asObservable();
  }

  public setItem(key: string, value: string) {
    if (!this.storageChanged[key])
      this.storageChanged[key] = new ReplaySubject(1);
    this.storageChanged[key].next(value);
  }

  public removeItem(key: string) {
    this.storageChanged[key] = null;
  }
}
