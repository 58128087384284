<div class="item" *ngIf="feature">
  <h3>{{ feature.label | translate }}</h3>
  <p>{{ feature.description | translate }}</p>
</div>
<mat-slide-toggle
  (change)="onToggle($event)"
  [checked]="enabled"
  (click)="$event.stopPropagation()"
  color="primary"
  [attr.data-cy-feature-toggle]="feature.key"
  [attr.data-cy-value]="enabled"
>
</mat-slide-toggle>
