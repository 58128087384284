import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { DetailsPageComponent } from './details-page/details-page.component';
import { DetailsDrawerRowComponent } from './details-drawer-row/details-drawer-row.component'
import { DetailsDrawerTitleComponent } from './details-drawer-title/details-drawer-title.component'

@NgModule({
  declarations: [
    DetailsPageComponent,
    DetailsDrawerRowComponent,
    DetailsDrawerTitleComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
  ],
  exports: [
    DetailsPageComponent,
    DetailsDrawerRowComponent,
    DetailsDrawerTitleComponent
  ]
})
export class DetailsPanelModule { }
export * from './details-drawer-row/details-drawer-row.component';
export * from './details-drawer-title/details-drawer-title.component';
export * from './details-page/details-page.component';	
