import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as zipkin from 'zipkin';
import { TracerService } from './tracer.service';

@Injectable()
export class JaegerInterceptor implements HttpInterceptor {
  public constructor(private tracerService: TracerService) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tracer = this.tracerService.createTracer();
    const instrumentation = new zipkin.Instrumentation.HttpClient({
      tracer: tracer,
      serviceName: 'Onyx.ClientApp',
      remoteServiceName: 'Onyx'
    });
    const zipkinOptions = instrumentation.recordRequest(
      {},
      req.urlWithParams,
      `${req.method} ${req.url}`
    );
    const traceId: string = tracer.id;
    const allHeaders = req.headers
      .keys()
      .reduce(
        (all, current) => all.set(current, req.headers.get(current)),
        new HttpHeaders(zipkinOptions.headers)
      );
    const reqWithZipKinHeaders = req.clone({
      headers: allHeaders
    });
    return next.handle(reqWithZipKinHeaders).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            instrumentation.recordResponse(traceId, event.status);
          }
        },
        error => instrumentation.recordError(traceId, error)
      )
    );
  }
}
