import { Component } from '@angular/core';

@Component({
  selector: 'onyx-spinner',
  template: `
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  `,
  styleUrls: ['spinner-component.scss']
})
export class SpinnerComponent {}
