import { Injectable, Inject } from '@angular/core';
import * as zipkin from 'zipkin';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ENVIRONMENT_TOKEN, Environment } from '@onyx/shared/common-imports';

@Injectable()
export class TracerService {
  private recorder: zipkin.BatchRecorder;
  public constructor(
    httpBackend: HttpBackend,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {
    const httpClient = new HttpClient(httpBackend);
    this.recorder = new zipkin.BatchRecorder({
      logger: {
        logSpan: span => {
          const encodedSpan = zipkin.jsonEncoder.JSON_V2.encode(span);
          return httpClient
            .post(environment.jaeger_span_endpoint, `[${encodedSpan}]`, {
              headers: { 'Content-Type': 'application/json' }
            })
            .toPromise();
        }
      }
    });
  }

  public createTracer() {
    return new zipkin.Tracer({
      ctxImpl: new zipkin.ExplicitContext(),
      recorder: this.recorder,
      sampler: new zipkin.sampler.CountingSampler(0.1),
      traceId128Bit: true,
      localServiceName: this.environment.tracer_service_name
    });
  }
}
