import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateModalComponent } from './update-modal.component';
import { CheckForUpdateService } from './check-for-update.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

export { CheckForUpdateService, UpdateModalComponent };

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [UpdateModalComponent],
  exports: [UpdateModalComponent]
})
export class UpdateModule {}
