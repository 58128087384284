import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'onyx-demo-login',
  templateUrl: './demo-login.component.html',
  styleUrls: ['./demo-login.component.css']
})
export class DemoLoginComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public ngOnInit() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    const route =
      queryParamMap.get('tenant') ||
      queryParamMap.get('redirectUri') ||
      'tenant';
    this.router.navigateByUrl(route);
  }
}
