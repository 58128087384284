import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  createPopper,
  Instance,
  OptionsGeneric,
  StrictModifiers
} from '@popperjs/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[uiPopper]'
})
export class PopperDirective implements OnInit, OnDestroy, OnChanges {
  // The hint to display
  @Input() target: HTMLElement;
  // Its positioning (check docs for available options)
  // Optional hint target if you desire using other element than specified one
  @Input() appPopper?: HTMLElement;
  // The popper instance

  @Input() popperOptions?: Partial<OptionsGeneric<StrictModifiers>>;
  @Input() allowHoverOverTarget = false;
  @Input() shouldPop = true;

  private popper: Instance;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shouldPop']) {
      if (changes['shouldPop'].currentValue === true) {
        this.initPopper();
      } else {
        this.ngOnDestroy();
      }
    }
    if (changes['allowHoverOverTarget']) {
      this.registerMouseEvents();
    }
    if (changes['popperOptions']) {
      this.setPopperOptions();
    }
    if (changes['target']) {
      this.ngOnDestroy();
      this.initPopper();
    }
  }

  private createPopperOptions(): Partial<OptionsGeneric<StrictModifiers>> {
    return {
      ...this.popperOptions,
      modifiers: [
        ...(this.popperOptions?.modifiers ?? []),
        {
          name: 'eventListeners',
          enabled: false
        }
      ]
    };
  }

  private createPopperInstance() {
    this.popper = createPopper<StrictModifiers>(
      this.reference,
      this.target,
      this.createPopperOptions()
    );
  }

  private setPopperOptions() {
    if (this.popper) {
      this.popper.setOptions(this.createPopperOptions());
    }
  }
  private get reference() {
    return this.appPopper ? this.appPopper : this.el.nativeElement;
  }

  private registerMouseEvents() {
    const relevantEvent$ = this.allowHoverOverTarget
      ? merge(
          fromEvent(this.reference, 'mouseenter'),
          fromEvent(this.reference, 'mouseleave'),
          fromEvent(this.target, 'mouseenter'),
          fromEvent(this.target, 'mouseleave')
        )
      : merge(
          fromEvent(this.reference, 'mouseenter'),
          fromEvent(this.reference, 'mouseleave')
        );

    relevantEvent$
      .pipe(
        filter(() => this.popper != null),
        takeUntil(this.destroy$)
      )
      .subscribe((e: MouseEvent) => {
        this.mouseHoverHandler(e);
      });
  }

  private initPopper() {
    // An element to position the hint relative to
    if (this.shouldPop) {
      this.createPopperInstance();
      this.registerMouseEvents();
    }
  }

  ngOnInit(): void {
    if (this.shouldPop) {
      this.initPopper();
    }
  }

  ngOnDestroy(): void {
    if (!this.popper) {
      return;
    }
    this.popper.destroy();
    this.popper = undefined;

    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  private async mouseHoverHandler(e: MouseEvent): Promise<void> {
    if (e.type === 'mouseenter') {
      await this.popper.setOptions({
        modifiers: [
          ...this.popper.state.options.modifiers,
          { name: 'eventListeners', enabled: true }
        ]
      });
      await this.popper.update();
    } else {
      await this.popper.setOptions({
        modifiers: [
          ...this.popper.state.options.modifiers,
          { name: 'eventListeners', enabled: false }
        ]
      });
    }
  }
}

@NgModule({
  imports: [],
  exports: [PopperDirective],
  declarations: [PopperDirective],
  providers: []
})
export class PopperDirectiveModule {}
