export enum NatsSubject {
  Transports = 'onyx.transports.data.*',
  TransportsCancel = 'onyx.transports.actions.cancel',
  TransportsSuspend = 'onyx.transports.actions.suspend',
  TransportsRelease = 'onyx.transports.actions.release',
  TransportsChangePriority = 'onyx.transports.actions.changePriority',
  TransportsCreate = 'onyx.transports.actions.create',
  Locations = 'onyx.locations.data.*',
  LocationsStoreLoads = 'onyx.locations.actions.storeLoad',
  LocationsClearLoads = 'onyx.locations.actions.clearLocations',
  LocationsChangeMode = 'onyx.locations.actions.changeMode',
  Vehicles = 'onyx.vehicles.data.*',
  VehiclesChangeMode = 'onyx.vehicles.actions.changeMode',
  VehiclesRemove = 'onyx.vehicles.actions.remove',
  VehiclesDeploy = 'onyx.vehicles.actions.deploy',
  VehiclesMaintenance = 'onyx.vehicles.actions.maintenance',
  VehiclesRestart = 'onyx.vehicles.actions.restart',
  VehiclesRetire = 'onyx.vehicles.actions.retire',
  VehiclesStop = 'onyx.vehicles.actions.stop',
  Restarted = 'onyx.status.restarted',
  FieldFunctions = 'onyx.fieldFunctions.data.*',
  Loads = 'onyx.loads.data.*',
  FleetManagerStatus = 'onyx.status.heartbeat',
  Jobs='onyx.jobs.data.*',
  JobsSuspend='onyx.jobs.actions.suspend',
  JobsCancel='onyx.jobs.actions.cancel',
}
