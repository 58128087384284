<mat-form-field>
  <mat-label>{{ filterLabel }}</mat-label>
  <input
    [formControl]="searchFieldControl"
    data-testid="search-input"
    type="text"
    matInput
  />
</mat-form-field>

<mat-table
  [dataSource]="dataSource"
  [trackBy]="trackBy"
  multiTemplateDataRows
  matSort
  [matSortActive]="defaultSortActive ?? key?.toString() ?? ''"
  [matSortDirection]="defaultSortDirection"
  data-cy-table
  *ngIf="structure"
>
  <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>

  <mat-row
    [attr.data-testid]="'row-' + row[key]"
    table-row
    *matRowDef="let row; columns: allColumns"
    [class]="rowClassSelector(row)"
    (click)="singleSelect(row)"
  ></mat-row>

  <div
    class="mat-row"
    *matNoDataRow
    class="no-data-row"
    data-testid="empty-row"
  >
    {{ emptyListReason() }}
  </div>

  <ng-container *ngFor="let column of columns" [matColumnDef]="column">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [attr.data-testid]="column + '-header'"
    >
      {{ structure[column] }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        [attr.data-testid]="column + '-cell'"
        *ngIf="!hasOverride(column)"
        uiHighlight
        [content]="element[column]"
        [searchTerm]="currentFilter"
      ></div>
      <div *ngIf="hasOverride(column)">
        <ng-container
          [ngTemplateOutlet]="hasOverride(column)!.template"
          [ngTemplateOutletContext]="{
            $implicit: element,
            currentFilter: currentFilter
          }"
        ></ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ structure['select'] }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-checkbox
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="toggleSelection(element)"
        [checked]="selection.isSelected(element[key])"
        [attr.data-select-id]="element[key]"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>
</mat-table>

<mat-paginator [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons>
</mat-paginator>

<div class="under-table">
  <ui-link-button
    data-testid="deselect-all"
    *ngIf="anythingSelected"
    (click)="deselectAll()"
    >{{ deselectLabel }}</ui-link-button
  >

  <ui-link-button
    data-testid="select-all"
    *ngIf="!anythingSelected"
    (click)="selectAll()"
    >{{ selectAllLabel }}</ui-link-button
  >
</div>
