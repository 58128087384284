import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { OnyxEventService } from '../onyx-event-service';
import { OnyxEvents } from '../onyx-events';

@Injectable()
export class OidcUserDataEventService implements OnyxEventService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private eventService: CustomComponentEventsService
  ) {}

  initialize() {
    this.eventService
      .onMicroFrontendLoaded(this.oidcSecurityService.userData$)
      .subscribe(userData =>
        this.eventService.publishEvent(OnyxEvents.UserData, userData.userData)
      );
  }
}
