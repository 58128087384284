import { Inject, Injectable } from '@angular/core';
import { MicroFrontendsService } from '../generated/services';
import { NavigationItem, SIDENAV_ITEMS } from '@onyx/navigation';
import { map, mergeAll } from 'rxjs/operators';
import { MainMenuOptions, MainMenuChildItem } from '../generated/models';

/**
 * Decouple loading micro frontends from the root navigation component (single responsibility principle)
 */
@Injectable()
export class MainMenuItemHookService {
  constructor(
    private microFrontendService: MicroFrontendsService,
    @Inject(SIDENAV_ITEMS) private navigationItems: NavigationItem[]
  ) {}

  /**
   * Convert the data coming from the Onyx platform to an item that the main menu component can handle.
   * @param menuItem - item coming from Onyx
   * @private
   */
  private static mapToNavigationItem(
    menuItem: MainMenuOptions
  ): NavigationItem {
    const mainPath = `app/${menuItem.tag}`;
    return {
      description: menuItem.description,
      path: mainPath,
      iconName: menuItem.iconName,
      children: menuItem.childItems?.map<NavigationItem>(childItem =>
        this.mapChildToNavigationItem(childItem, mainPath)
      ),
      navTop: true,
      feature: menuItem.feature
    };
  }

  private static mapChildToNavigationItem(
    childItem: MainMenuChildItem,
    mainPath: string
  ): NavigationItem {
    return {
      description: childItem.description,
      path: mainPath + '/' + childItem.path,
      iconName: childItem.iconName
    };
  }

  /**
   * Requests the externally added main menu items from the Onyx platform and add them to the main menu.
   */
  loadMainMenuItems() {
    return this.microFrontendService
      .menuItems()
      .pipe(
        mergeAll(),
        map(menuOption =>
          MainMenuItemHookService.mapToNavigationItem(menuOption)
        )
      )
      .subscribe(menuItem => {
        this.navigationItems.push(menuItem);
      });
  }
}
