import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  NgModule,
  Output
} from '@angular/core';

@Component({
  selector: 'ui-link-button',
  template: `
    <a class="link-button" (click)="click.emit($event)"
      ><ng-content></ng-content
    ></a>
  `,
  styles: [
    `
      :host {
        a {
          cursor: pointer;
          font-size: 16px;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkButtonComponent {
  //explicitly want an override of click only here.
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<MouseEvent>();
}

@NgModule({
  imports: [],
  exports: [LinkButtonComponent],
  declarations: [LinkButtonComponent],
  providers: []
})
export class LinkButtonComponentModule {}
