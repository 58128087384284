import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPanelModule } from '@onyx-ui';
import { CreateJobPresenterComponent } from './create-job-presenter/create-job-presenter.component';
import { JobDetailsPresenterComponent } from './job-details-presenter/job-details-presenter.component';

@NgModule({
  declarations: [CreateJobPresenterComponent, JobDetailsPresenterComponent],
  exports: [CreateJobPresenterComponent, JobDetailsPresenterComponent],
  imports: [CommonModule, TranslateModule, DetailsPanelModule, MatButtonModule]
})
export class JobPanelsModule {}
