import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as Popper from '@popperjs/core';
import { StrictModifiers } from '@popperjs/core';
import { filter } from 'rxjs/operators';
import { NavigationItem } from '../nav-item/navigation-item';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'onyx-nav-item, [onyx-nav-item]',
  templateUrl: './nav-item.component.html'
})
export class NavItemComponent implements OnInit, OnChanges {
  @HostBinding('class.collapsable') get collapsable() {
    return this.navItem.children;
  }

  constructor(router: Router) {
    this.router = router;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.classActive = this.isRouteActive();
        const isChildRouteActive = this.isChildRouteActive();
        this.classToggleClosed = !isChildRouteActive;
        this.classToggleOpen = isChildRouteActive;
        this.checkPopperLifecycle();
      });
  }
  @HostBinding('class.active') classActive = false;
  @HostBinding('class.toggle-open') classToggleOpen = false;
  @HostBinding('class.toggle-closed') classToggleClosed = true;
  @Input() public text: string;
  @Input() public showText: boolean;
  @Input() public navItem: NavigationItem;
  @Input() public isHandset: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  private router: Router;

  @ViewChild('subNav')
  subNav?: ElementRef<HTMLElement>;

  @ViewChild('link')
  link?: ElementRef<HTMLElement>;

  public shouldPopSubMenu: boolean;
  public childRoutesOpen: boolean;

  sideNavPopperOptions: Partial<Popper.OptionsGeneric<StrictModifiers>> = {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [26, 0]
        }
      },
      {
        name: 'preventOverflow',
        options: {
          altBoundary: true
        }
      }
    ]
  };

  labelPopperOptions: Partial<Popper.OptionsGeneric<StrictModifiers>> = {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      },
      {
        name: 'preventOverflow',
        options: {
          altBoundary: true
        }
      }
    ]
  };

  @HostListener('click') onClick() {
    if (!this.collapsable) this.close.next(undefined);
  }

  ngOnInit(): void {
    this.classActive = this.isRouteActive();
    const isChildRouteActive = this.isChildRouteActive();
    this.classToggleClosed = !isChildRouteActive;
    this.classToggleOpen = isChildRouteActive;
    this.checkPopperLifecycle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showText']) {
      this.checkPopperLifecycle();
    }
  }

  private checkPopperLifecycle() {
    const childRouteActive = this.isChildRouteActive();
    this.shouldPopSubMenu = !this.showText && !childRouteActive;
  }

  public isRouteActive(): boolean {
    if (!this.navItem.children) {
      return this.router.isActive(this.getFullPath(this.navItem.path), {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored'
      });
    } else {
      return this.isChildRouteActive();
    }
  }

  public isChildRouteActive(): boolean {
    let isActive = false;
    this.navItem.children?.forEach(child => {
      if (
        this.router.isActive(this.getFullPath(child.path), {
          paths: 'exact',
          queryParams: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored'
        })
      )
        isActive = true;
    });
    return isActive;
  }

  public toggleSubnav(): void {
    this.classToggleClosed = !this.classToggleClosed;
    this.classToggleOpen = !this.classToggleOpen;
  }

  private getFullPath(navItemPath: string) {
    return '/' + navItemPath;
  }
}
