// import all necessary cultures
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/zh';

export interface OnyxCulture {
  fullName: string;
}

export const SUPPORTED_CULTURES = {
  en: {
    fullName: 'English'
  },
  nl: {
    fullName: 'Nederlands'
  },
  de: {
    fullName: 'Deutsch'
  },
  es: {
    fullName: 'Español'
  },
  fr: {
    fullName: 'Français'
  },
  it: {
    fullName: 'Italiano'
  },
  zh: {
    fullName: 'Chinese'
  }
};
