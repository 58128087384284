import { Component } from '@angular/core';

@Component({
  template: `
    <h1>{{ 'noTenants.title' | translate }}</h1>
    <p>{{ 'noTenants.text' | translate }}</p>
  `,
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-flow: column;
      }
      h1 {
        font-weight: 100;
        color: #777777;
      }
      p {
        font-size: 20px;
        color: #999999;
      }
    `
  ]
})
export class NoTenantsComponent {}
