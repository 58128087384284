import { OnyxEvents } from '../onyx-events';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { ReplaySubject } from 'rxjs';
import { OnyxEventService } from '../onyx-event-service';
import { Injectable } from '@angular/core';

export enum SystemOverviewEntityType {
  None = 0,
  Vehicle,
  Location,
  Transport
}

@Injectable({ providedIn: 'root' })
export class SystemOverviewSelectedEntityService implements OnyxEventService {
  private readonly selectedEntity$ = new ReplaySubject<{
    type: SystemOverviewEntityType;
    selectedEntityId: any;
  }>(1);

  constructor(private eventService: CustomComponentEventsService) {}

  public initialize() {
    this.eventService
      .onMicroFrontendLoaded(this.selectedEntity$)
      .subscribe(x => {
        this.eventService.publishEvent(OnyxEvents.SelectedEntityId, x);
      });
  }

  public changeSelectedEntity(
    type: SystemOverviewEntityType,
    selectedEntityId: any
  ) {
    this.selectedEntity$.next({ type, selectedEntityId: selectedEntityId });
  }
}
