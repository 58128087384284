<div
  class="root-container"
  [class.open]="isMenuOpen"
  [class.closed]="!isMenuOpen"
  [class.handset]="isHandset"
>
  <nav class="sidenav" *ngIf="showNavBar">
    <div class="nav-top">
      <onyx-nav-item
        *ngFor="let item of navigationListItems$ | async"
        [text]="item.description | translate"
        [showText]="isMenuOpen"
        [navItem]="item"
        (close)="closeIfHandset()"
      ></onyx-nav-item>
    </div>

    <div class="nav-bottom">
      <onyx-nav-item
        *ngFor="let item of navigationListItemsBottom$ | async"
        [text]="item.description | translate"
        (click)="closeIfHandset()"
        [showText]="isMenuOpen"
        [navItem]="item"
      ></onyx-nav-item>

      <onyx-nav-item
        *ngIf="lastVisitedAgv$ | async as agv"
        [text]="'Monitor ' + agv"
        [showText]="isMenuOpen"
        (click)="openMonitor()"
        [navItem]="STATIC_NAVITEMS['monitor']"
      ></onyx-nav-item>

      <onyx-nav-item
        text="{{ 'nav.settings' | translate }}"
        [showText]="isMenuOpen"
        routerLinkActive="active"
        (click)="closeIfHandset()"
        [navItem]="STATIC_NAVITEMS['settings']"
      ></onyx-nav-item>

      <onyx-nav-item
        *ngIf="!isHandset"
        class="toggle"
        data-cy="side-nav-toggle"
        [showText]="isMenuOpen"
        (click)="toggleMenu(!isMenuOpen)"
        text="{{ 'nav.toggleMenu' | translate }}"
        [navItem]="STATIC_NAVITEMS['sidenavToggle']"
      ></onyx-nav-item>
    </div>
  </nav>
  <section class="content">
    <ng-content></ng-content>
  </section>
</div>
