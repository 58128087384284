import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationResult } from './confirmation-result';

@Component({
  selector: 'onyx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public message: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: { message: string }
  ) {
    this.message = dialogData.message;
  }

  public cancel() {
    this.dialogRef.close(ConfirmationResult.Cancel);
  }

  public ok() {
    this.dialogRef.close(ConfirmationResult.Confirm);
  }
}
