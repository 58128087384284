import { Component } from '@angular/core';
import { FeatureService } from '../feature.service';
import { Feature } from '../feature';
import { Observable } from 'rxjs';
import { FeatureOverlayRef } from './feature-overlay.ref';

@Component({
  selector: 'onyx-feature-overlay',
  templateUrl: './feature-overlay.component.html',
  styleUrls: ['./feature-overlay.component.scss']
})
export class FeatureOverlayComponent {
  public features$: Observable<Feature[]>;

  constructor(
    private featureService: FeatureService,
    private overlayRef: FeatureOverlayRef
  ) {
    this.features$ = featureService.allExperimentalFeatures$;
  }

  public hasFeatureEnabled(featureKey: string) {
    return this.featureService.hasFeatureEnabled(featureKey);
  }

  public toggleFeature(featureKey: string, enabled: boolean) {
    this.featureService.setExperimentalFeatureState(featureKey, enabled);
  }

  public close() {
    this.overlayRef.close();
  }
}
