import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnyxCommonModule } from '@onyx/shared/common';

export * from './notification-hub.service';
export * from './notificationsTranslation.service';

@NgModule({
  imports: [CommonModule, OnyxCommonModule]
})
export class NotificationsModule {}
