import { Injectable } from '@angular/core';
import { Observable, fromEvent, Subject, merge } from 'rxjs';
import { Setting, SettingValue } from './settings';
import { InitialSettingsLoader } from './initial-settings-loader';
import { take, filter, map, startWith, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly localStorageKey = 'onyx:settings';
  private settingsChanged$ = new Subject<string>();
  // this stream will emit settings once on startup and every time the settings are changed
  public settings$: Observable<Setting[]>;
  public darkThemeSetting$: Observable<boolean>;

  public languageSetting$: Observable<string>;

  public constructor(private initialSettingsLoader: InitialSettingsLoader) {
    // get a stream of the save storage events from other windows with our settings key
    const settingsChangedExternal$ = fromEvent(window, 'storage').pipe(
      filter((x: StorageEvent) => x.key === this.localStorageKey),
      map(x => x.newValue)
    );
    // merge the storage event stream with the local settings changed stream
    const settingsChanged$ = merge(
      settingsChangedExternal$,
      this.settingsChanged$
    );
    // parse the string to settings object
    this.settings$ = settingsChanged$.pipe(
      // start the stream with the current saved settings, if they aren't present, use the initial settings
      startWith(JSON.stringify(this.getInitial())),
      map(x => JSON.parse(x) as Setting[]),
      shareReplay(1)
    );

    this.darkThemeSetting$ = this.settings$.pipe(
      map(x => this.getSetting('appearance.dark_theme', x).value as boolean)
    );
    this.languageSetting$ = this.settings$.pipe(
      map(x => this.getSetting('language.ui-language', x).value as string)
    );
  }

  private getSetting(path: string, settings: Setting[]) {
    return path.split('.').reduce<Setting>(
      (setting, key) => {
        const set = setting.children.find(x => x.key === key);
        return set;
      },
      { key: 'root', label: 'root', feature: '', children: settings }
    );
  }

  private getInitial() {
    const stored = localStorage.getItem(this.localStorageKey);
    const initial = this.initialSettingsLoader.getInitialSettings();
    if (stored) {
      this.complete(initial, JSON.parse(stored));
    }
    return initial;
  }

  private complete(settingsX: Setting[], settingsY: Setting[]) {
    settingsX.forEach(settingX => {
      const settingY = settingsY.find(x => x.key === settingX.key);
      if (settingY) {
        if (settingX.children && settingY.children) {
          this.complete(settingX.children, settingY.children);
        } else if (
          Object.getOwnPropertyNames(settingX).includes('value') &&
          Object.getOwnPropertyNames(settingY).includes('value')
        ) {
          settingX.value = settingY.value;
        }
      }
    });
  }

  public async setSetting(path: string, value: SettingValue) {
    // get the current settings that are in memory
    const settings = await this.settings$.pipe(take(1)).toPromise();
    // get the referenced setting from the given path
    const settingToSet = this.getSetting(path, settings);
    // only change the setting when it is different from the current value
    if (settingToSet.value !== value) {
      settingToSet.value = value;
      this.persist(settings);
    }
  }

  private persist(settings: Setting[]) {
    const serialized = JSON.stringify(settings);
    localStorage.setItem(this.localStorageKey, serialized);
    this.settingsChanged$.next(serialized);
  }
}
