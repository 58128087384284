import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationDtoType } from './models/notification-dto-type';
import { NotificationTranslationService } from './notificationsTranslation.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationHubService {
  constructor(
    private snackBarService: MatSnackBar,
    private notificationTranslationService: NotificationTranslationService
  ) {}

  public notifyFailure(message: string, translationKey?: string) {
    return this.snackBarService.open(
      this.notificationTranslationService.toTranslatedMessage({
        message,
        translationKey,
        type: NotificationDtoType.Error
      }),
      null,
      {
        panelClass: 'snack-bar-warning',
        duration: 5000
      }
    );
  }

  public notifyException(
    message: string,
    exception: string,
    translationKey?: string
  ) {
    return this.snackBarService.open(
      this.notificationTranslationService.toTranslatedMessage({
        message,
        translationKey,
        type: NotificationDtoType.Error,
        exception
      }),
      null,
      {
        panelClass: 'snack-bar-warning',
        duration: 5000
      }
    );
  }
}
