import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NatsRebootListenerService } from '@onyx/nats';
import {
  ConnectionMessageService,
  ConnectionSnackBarComponent,
  FullscreenService,
  ResponsiveService
} from '@onyx/shared/common';
import { Environment, ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';
import {
  FeatureOverlayRef,
  FeatureOverlayService
} from '@onyx/shared/feature-flags';
import { SettingsService } from '@onyx/shared/settings';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, from, merge, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { RootNavigationComponent } from '../root-navigation/root-navigation.component';

const BROWSER_CONFIG: {
  [key: string]: { faviconUrl: string; title: string };
} = {
  onyx: { faviconUrl: 'favicon.ico', title: 'Onyx' },
  dematic: {
    faviconUrl: 'assets/images/dematic_favicon.ico',
    title: 'Dematic'
  },
  linde: {
    faviconUrl: 'assets/images/linde_favicon.ico',
    title: 'Linde Material Handling'
  },
  kion: { faviconUrl: 'assets/images/favicon.ico', title: 'Onyx' },
  still: {
    faviconUrl: 'assets/images/still_favicon.ico',
    title: 'Still'
  }
};

@Component({
  selector: 'onyx-tenant-shell',
  templateUrl: './tenant-shell.component.html',
  styleUrls: ['./tenant-shell.component.scss'],
  providers: []
})
export class TenantShellComponent implements OnDestroy {
  public isHandset$: Observable<boolean>;
  public loadingTenant$: Observable<boolean>;
  public menuOpen = false;
  public showNavBar$: Observable<boolean>;
  public darkTheme$: Observable<boolean>;
  @ViewChild('nav', { static: false })
  public navigation: RootNavigationComponent;
  private destroy$ = new Subject();
  private featuresOverlayRef: FeatureOverlayRef;
  private popoutCount = 0;

  constructor(
    private titleService: Title,
    private featureOverlayService: FeatureOverlayService,
    private oidcSecurityService: OidcSecurityService,
    private snackBarService: MatSnackBar,
    connectionMessageService: ConnectionMessageService,
    settingsService: SettingsService,
    activatedRoute: ActivatedRoute,
    responsiveService: ResponsiveService,
    fullscreenService: FullscreenService,
    private translate: TranslateService,
    _rebootListener: NatsRebootListenerService, // this service is injected in order to activate it once
    @Inject(ENVIRONMENT_TOKEN) environment: Environment
  ) {
    const hideToolbar$ = activatedRoute.queryParams.pipe(
      filter(qp => qp['hidetoolbar']),
      map(qp => qp['hidetoolbar'] === 'true'),
      startWith(false),
      takeUntil(this.destroy$)
    );

    this.isHandset$ = responsiveService.responsiveFlags$.pipe(
      map(x => x.handset)
    );
    let snackBarRef: MatSnackBarRef<ConnectionSnackBarComponent>;

    connectionMessageService.connectionState$
      .pipe(
        map(x => x.hasFaultyConnections),
        distinctUntilChanged()
      )
      .subscribe(x => {
        if (x) {
          snackBarRef = this.snackBarService.openFromComponent(
            ConnectionSnackBarComponent,
            {
              panelClass: 'snack-bar-warning'
            }
          );
        } else if (snackBarRef) {
          snackBarRef.dismiss();
          snackBarRef = null;
        }
      });
    connectionMessageService.connectionState$
      .pipe(
        filter(
          x =>
            x.hasConnections && !x.hasFaultyConnections && x.previousHadFaulty
        )
      )
      .subscribe(() => {
        this.snackBarService.open(
          this.translate.instant('connection.reconnectSuccess'),
          null,
          {
            panelClass: 'snack-bar-success',
            duration: 5000
          }
        );
      });

    this.darkTheme$ = settingsService.darkThemeSetting$;

    const theme$ = from([environment.brand]);

    theme$.pipe(takeUntil(this.destroy$)).subscribe(t => {
      const allThemes = Object.keys(BROWSER_CONFIG);
      document.body.classList.remove(...allThemes);
      document.body.classList.add(t);
    });

    this.loadingTenant$ = merge(from([true]), theme$.pipe(mapTo(false)));

    theme$
      .pipe(
        map(theme => BROWSER_CONFIG[theme]),
        takeUntil(this.destroy$)
      )
      .subscribe(browserConfig => {
        this.titleService.setTitle(browserConfig.title);
        document
          .getElementById('favicon')
          ?.setAttribute('href', browserConfig.faviconUrl);
      });

    this.showNavBar$ = combineLatest([
      fullscreenService.shouldBeFullscreen$,
      this.isHandset$,
      hideToolbar$
    ]).pipe(
      map(
        ([shouldBeFullScreen, isHandSet, hidetoolbar]) =>
          (isHandSet || !shouldBeFullScreen) && !hidetoolbar
      )
    );

    this.showNavBar$
      .pipe(takeUntil(this.destroy$))
      .subscribe(shouldShowToolbar => {
        if (!shouldShowToolbar) {
          document.body.classList.add('toolbar-hidden');
        }
      });
  }

  public openFeaturesOverlay() {
    if (this.featuresOverlayRef) {
      this.featuresOverlayRef.close();
    } else {
      this.featuresOverlayRef =
        this.featureOverlayService.openFeaturesOverlay();
      this.featuresOverlayRef.closings().subscribe(() => {
        this.featuresOverlayRef = null;
      });
    }
  }

  public openMobileMenu() {
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen) {
      this.navigation.open();
    } else {
      this.navigation.close();
    }
  }

  public onNavigationItemClicked() {
    this.navigation.close();
    this.menuOpen = false;
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  public popup() {
    const windowFeatures =
      'menubar=no,location=no ,resizable=yes,scrollbars=no,status=no';

    window.open(
      `${location.href}?hidetoolbar=true`,
      location.href + ++this.popoutCount,
      windowFeatures
    );
  }
}
