import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { takeUntil, switchMap, filter, mapTo } from 'rxjs/operators';
import { Subject, Observable, merge, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  CheckForUpdateService,
  UpdateModalComponent
} from '@onyx/shared/update';
import { Router, ResolveStart, ResolveEnd } from '@angular/router';
import {
  ResponsiveService,
  BrowserSupportService,
  NotifyDialogComponent
} from '@onyx/shared/common';
import { SettingsService } from '@onyx/shared/settings';

@Component({
  selector: 'onyx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public routeIsLoaded$: Observable<boolean>;
  public constructor(
    router: Router,
    swUpdate: SwUpdate,
    checkForUpdateService: CheckForUpdateService,
    matDialog: MatDialog,
    responsiveService: ResponsiveService,
    browserSupportService: BrowserSupportService,
    settingsService: SettingsService
  ) {
    if (!browserSupportService.isSupported()) {
      matDialog.open(NotifyDialogComponent, {
        data: {
          message: 'Your browser is not supported by the ONYX platform.'
        }
      });
    }

    settingsService.darkThemeSetting$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => document.body.classList.toggle('dark-theme', x));

    const startRouteResolve$ = router.events.pipe(
      filter(x => x instanceof ResolveStart),
      mapTo(false)
    );
    const endRouteResolve$ = router.events.pipe(
      filter(x => x instanceof ResolveEnd),
      mapTo(true)
    );
    this.routeIsLoaded$ = merge(startRouteResolve$, endRouteResolve$);

    if (swUpdate.isEnabled) {
      checkForUpdateService.checkForUpdateTrigger$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          swUpdate.checkForUpdate();
        });
      swUpdate.available
        .pipe(
          // if an update is available, open a modal and wait for the closed event
          switchMap(() => matDialog.open(UpdateModalComponent).afterClosed()),
          takeUntil(this.destroy$)
        )
        .subscribe(async result => {
          // if they chose to refresh, activate the update, and reload the page
          if (result) {
            await swUpdate.activateUpdate();
            window.location.reload();
          }
        });
    }

    responsiveService.responsiveFlags$
      .pipe(takeUntil(this.destroy$))
      .subscribe(flags => {
        document.body.classList.toggle('handset', flags.handset);
        document.body.classList.toggle('tablet', flags.tablet);
        document.body.classList.toggle('landscape', flags.landscape);
      });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
  }

  public ngOnInit(): void {
    const loader = document.getElementById('onyx-loader');
    loader.parentNode.removeChild(loader);
  }

  public ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}
