import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { NatsRebootPopupComponent } from './nats-reboot-popup/nats-reboot-popup.component';
import { NatsSubject } from './nats-subject';
import { NatsClient } from '@onyx/ngx-nats';

@Injectable({ providedIn: 'root' })
export class NatsRebootListenerService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(nats: NatsClient, matDialog: MatDialog) {
    nats
      .getStream<void>(NatsSubject.Restarted)
      .pipe(
        switchMap(() => matDialog.open(NatsRebootPopupComponent).afterClosed()),
        takeUntil(this.destroy$)
      )
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.reloadPage();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //separate reload call to be able to test it in karma (otherwise there's an infite reload loop!)
  reloadPage() {
    window.location.reload();
  }
}
