import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export * from './custom-material/custom-material.module';
export * from './environment.provider';

@NgModule({
  exports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule
  ],
  imports: [CommonModule, MatIconModule]
})
export class CommonImportsModule {}
