import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { FeatureService } from './feature.service';
import { ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil
} from 'rxjs/operators';

@Directive({
  selector: '[onyxFeature]'
})
export class FeatureDirective implements OnDestroy, OnInit {
  private currentFeatureKey$: Subject<string>;
  private onDestroy$: Subject<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureService: FeatureService
  ) {
    this.currentFeatureKey$ = new ReplaySubject(1);
    this.onDestroy$ = new Subject<any>();
  }

  @Input()
  set onyxFeature(feature: string) {
    this.currentFeatureKey$.next(feature);
  }

  ngOnInit(): void {
    this.currentFeatureKey$
      .pipe(
        filter(x => !!x || x === ''),
        switchMap(featureKey =>
          this.featureService.hasFeatureEnabled(featureKey)
        ),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(enabled => {
        if (enabled) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
