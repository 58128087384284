import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Optional
} from '@angular/core';
import { DynamicComponent } from './dynamic/dynamic.component';
import { MainMenuItemHookComponent } from './main-menu-item-hook/main-menu-item-hook.component';
import { MainMenuItemHookService } from './main-menu-item-hook/main-menu-item-hook.service';
import { MicrofrontendTranslationService } from './microfrontend-translation.service';
import { LanguageChangeService } from './onyx-events/event-services/language-change-event-service';
import { OidcTokenEventService } from './onyx-events/event-services/oidc-token-event-service';
import { OidcUserDataEventService } from './onyx-events/event-services/oidc-userdata-event-service';
import { RouteChangedEventService } from './onyx-events/event-services/route-changed-event-service';
import { SupportedLanguagesEventService } from './onyx-events/event-services/supported-languages-event.service';
import {
  SystemOverviewEntityType,
  SystemOverviewSelectedEntityService
} from './onyx-events/event-services/system-overview-selected-entity.service';
import { OnyxEventInitializerService } from './onyx-events/onyx-event-initializer.service';
import { EVENT_SERVICES } from './onyx-events/onyx-event-services-token';

export { CustomComponentLoaderService } from './custom-component-loader.service';
export * from './generated/models';
export * from './generated/services';
export { CustomComponentEventsService } from './onyx-events/custom-component-events.service';
export { OnyxEvents } from './onyx-events/onyx-events';
export { MicrofrontendTranslationService };
export { SystemOverviewSelectedEntityService, SystemOverviewEntityType };

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MainMenuHookRoute = {
  path: 'app/:component',
  pathMatch: 'prefix',
  children: [
    {
      path: '**',
      component: MainMenuItemHookComponent
    }
  ]
};

@NgModule({
  imports: [CommonModule],
  declarations: [MainMenuItemHookComponent, DynamicComponent],
  exports: [DynamicComponent],
  providers: [
    OnyxEventInitializerService,
    { provide: EVENT_SERVICES, useClass: LanguageChangeService, multi: true },
    {
      provide: EVENT_SERVICES,
      useClass: SupportedLanguagesEventService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [OnyxEventInitializerService],
      useFactory: (initializerService: OnyxEventInitializerService) => () =>
        initializerService.init()
    }
  ]
})
export class MicroFrontendsModule {
  constructor(
    @Optional()
    _microfrontendTranslationsService: MicrofrontendTranslationService
  ) {}

  static withOffboardHooks(): ModuleWithProviders<MicroFrontendsModule> {
    return {
      ngModule: MicroFrontendsModule,
      providers: [
        MainMenuItemHookService,
        {
          provide: EVENT_SERVICES,
          useClass: OidcTokenEventService,
          multi: true
        },

        {
          provide: EVENT_SERVICES,
          useExisting: SystemOverviewSelectedEntityService,
          multi: true
        },
        {
          provide: EVENT_SERVICES,
          useClass: RouteChangedEventService,
          multi: true
        },
        {
          provide: EVENT_SERVICES,
          useClass: OidcUserDataEventService,
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: (mainMenuHooks: MainMenuItemHookService) => () => {
            if (mainMenuHooks) {
              mainMenuHooks.loadMainMenuItems();
            }
          },
          deps: [MainMenuItemHookService]
        }
      ]
    };
  }
}
