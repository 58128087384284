import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { ActivatedRoute, Router, ActivationStart } from '@angular/router';
import { map, filter, startWith, shareReplay } from 'rxjs/operators';

@Injectable()
export class FullscreenService {
  public constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public get shouldBeFullscreen$(): Observable<boolean> {
    const canBeFullscreen$ = this.router.events.pipe(
      filter<ActivationStart>(x => x instanceof ActivationStart),
      map(x => x.snapshot.data['canBeFullscreen']),
      startWith(this.getInitialCanBeFullsceenValue()),
      shareReplay(1)
    );

    const hasFullScreenQueryParam$ = this.activatedRoute.queryParamMap.pipe(
      map(x => (x.get('fullscreen') === 'true' ? true : false))
    );

    return combineLatest([hasFullScreenQueryParam$, canBeFullscreen$]).pipe(
      map(([x, y]) => x && y)
    );
  }

  private getInitialCanBeFullsceenValue() {
    let snapshot = this.activatedRoute.snapshot;
    while (snapshot) {
      const canBeFullscreen = snapshot.data['canBeFullscreen'];
      if (canBeFullscreen !== undefined) return canBeFullscreen;
      snapshot = snapshot.firstChild;
    }
    return false;
  }

  public updateFullscreenQueryParam(newValue: boolean) {
    this.router.navigate([], {
      queryParams: {
        fullscreen: newValue
      },
      queryParamsHandling: 'merge'
    });
  }
}
