import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonImportsModule } from '@onyx/shared/common-imports';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { ClaimEnabledButtonDirective } from './claim-enabled/claim-enabled-button-directive';
import { ClaimEnabledComponent } from './claim-enabled/claim-enabled.component';
import { ClaimRequiredDirective } from './claim-required.directive';
import { DemoLoginComponent } from './demo-login/demo-login.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export * from './auto-login/auto-login.component';
export * from './claim-authorization.service';
export * from './demo-login/demo-login.component';
export * from './sign-in/sign-in.component';
export * from './unauthorized/unauthorized.component';
export * from './claim-enabled/claim-enabled.component';

@NgModule({
  imports: [CommonModule, CommonImportsModule, TranslateModule],
  declarations: [
    SignInComponent,
    UnauthorizedComponent,
    AutoLoginComponent,
    ClaimRequiredDirective,
    DemoLoginComponent,
    ClaimEnabledComponent,
    ClaimEnabledButtonDirective
  ],
  exports: [
    SignInComponent,
    UnauthorizedComponent,
    AutoLoginComponent,
    ClaimRequiredDirective,
    DemoLoginComponent,
    ClaimEnabledComponent,
    ClaimEnabledButtonDirective
  ]
})
export class SharedAuthenticationModule {}
