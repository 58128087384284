import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PopperDirectiveModule } from '@onyx-ui';
import { NotificationsModule } from '@onyx/notifications';
import { OnyxCommonModule } from '@onyx/shared/common';
import {
  CommonImportsModule,
  CustomMaterialModule
} from '@onyx/shared/common-imports';
import { SharedFeatureFlagsModule } from '@onyx/shared/feature-flags';
import { UserIdleModule } from 'angular-user-idle';
import { NavItemComponent } from './nav-item/nav-item.component';
import { PinButtonComponent } from './pin-button/pin-button.component';
import { ProfilePopupComponent } from './profile-popup/profile-popup.component';
import { RootNavigationComponent } from './root-navigation/root-navigation.component';
import { TenantShellComponent } from './tenant-shell/tenant-shell.component';

export * from './nav-item/navigation-item';
export * from './root-navigation/root-navigation.component';
export * from './root-navigation/sidenav-items-token';
export * from './tenant-shell/tenant-shell.component';

@NgModule({
  imports: [
    CommonModule,
    OnyxCommonModule,
    CustomMaterialModule,
    CommonImportsModule,
    RouterModule,
    SharedFeatureFlagsModule,
    NotificationsModule,
    UserIdleModule.forRoot({}),
    PopperDirectiveModule
  ],
  declarations: [
    RootNavigationComponent,
    TenantShellComponent,
    NavItemComponent,
    PinButtonComponent,
    ProfilePopupComponent
  ],
  exports: [RootNavigationComponent, TenantShellComponent]
})
export class NavigationModule {}
