import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'onyx-dynamic-component',
  template: ''
})
export class DynamicComponent implements OnChanges, OnDestroy {
  @Input() public tagName: string;
  private tagName$ = new Subject<string>();
  private onDestroy$ = new Subject();
  public constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.tagName$
      .pipe(
        filter(x => !!x),
        takeUntil(this.onDestroy$)
      )
      .subscribe(x => {
        renderer.setProperty(elementRef.nativeElement, 'innerHTML', '');
        renderer.appendChild(
          elementRef.nativeElement,
          renderer.createElement(x)
        );
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tagName) {
      this.tagName$.next(this.tagName);
    }
  }

  public ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
