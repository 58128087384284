import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { HighlightDirectiveModule } from '../highlight/highlight.directive';
import { LinkButtonComponentModule } from '../link-button/link-button.component';
import { PopperDirectiveModule } from '../popper/popper.directive';
import { ListCellComponent } from './list-cell/list-cell.component';
import { OverrideColumnDirective } from './table/override-column.directive';
import { TableComponent } from './table/table.component';
import { ToggleCellComponent } from './toggle-cell/toggle-cell.component';

export * from './list-cell/list-cell.component';
export * from './table/override-column.directive';
export * from './table/table.component';
export * from './toggle-cell/toggle-cell.component';
@NgModule({
  declarations: [
    TableComponent,
    OverrideColumnDirective,
    ListCellComponent,
    ToggleCellComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    HighlightDirectiveModule,
    LinkButtonComponentModule,
    PopperDirectiveModule
  ],
  exports: [
    TableComponent,
    OverrideColumnDirective,
    ListCellComponent,
    ToggleCellComponent
  ]
})
export class GenericTableModule {}
