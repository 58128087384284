import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OnyxEvents } from '../onyx-events';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OnyxEventService } from '../onyx-event-service';

@Injectable()
export class RouteChangedEventService implements OnyxEventService {
  constructor(
    private eventService: CustomComponentEventsService,
    private router: Router
  ) {}

  initialize() {
    // Broadcast route changes to micro-frontends
    let lastNavStart: NavigationStart;
    const navStart$ = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart)
    );
    this.eventService.onMicroFrontendLoaded(navStart$).subscribe(navStart => {
      lastNavStart = navStart;
      this.eventService.publishEvent(OnyxEvents.OnyxRouteChanged, navStart.url);
    });

    // Listen to route changes from micro-frontends
    fromEvent(window, OnyxEvents.MicroFrontendRouteChanged)
      .pipe(map((e: any) => e.detail))
      .subscribe(url => {
        if (lastNavStart.url !== url) {
          this.router.navigateByUrl(url, {
            skipLocationChange: true
          });
        }
      });
  }
}
