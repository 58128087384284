import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({ selector: '[onyxClickOutside]' })
export class OnyxClickOutsideDirective {
  @Input() public clickTarget: ElementRef<HTMLElement>;
  @Input() public clickOutsideEnabled: boolean;
  @Output() public clickOutside = new EventEmitter<void>();

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  @HostListener('document:click', ['$event'])
  documentClickHandler(event: MouseEvent) {
    if (
      this.clickOutsideEnabled &&
      !this.elementRef.nativeElement.contains(event.target as Node) &&
      !this.clickTarget?.nativeElement?.contains(event.target as Node)
    ) {
      this.clickOutside.emit();
    }
  }
}
