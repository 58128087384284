import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingComponent } from './setting/setting.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { SharedAuthenticationModule } from '@onyx/shared/authentication';
import { ExternalAuthComponent } from './external-auth/external-auth.component';
import { IdentityProviderDetailsComponent } from './identity-provider-details/identity-provider-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedFeatureFlagsModule } from '@onyx/shared/feature-flags';
import { ChangeLogComponent } from './changelog/changelog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChangeLogEntryComponent } from './changelog-entry/changelog-entry.component';
import { OnyxCommonModule } from '@onyx/shared/common';

export * from './settings.service';
export * from './initial-settings-loader';
export * from './settings';

@NgModule({
  imports: [
    CommonModule,
    OnyxCommonModule,
    MatListModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatRippleModule,
    RouterModule.forChild([
      { path: '', pathMatch: 'full', component: SettingsPageComponent }
    ]),
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    ReactiveFormsModule,
    SharedAuthenticationModule,
    SharedFeatureFlagsModule,
    TranslateModule.forChild({ extend: true })
  ],
  declarations: [
    ChangeLogComponent,
    ChangeLogEntryComponent,
    SettingsPageComponent,
    SettingComponent,
    ExternalAuthComponent,
    IdentityProviderDetailsComponent
  ],
  providers: []
})
export class SharedSettingsModule {}
