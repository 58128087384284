import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OptionsGeneric, StrictModifiers } from '@popperjs/core';
import { joinAndTruncateList } from '../../display-helpers/truncate-list';

@Component({
  selector: 'ui-list-cell',
  template: `
    <div
      class="popper-target"
      *ngIf="displayPopup"
      uiPopper
      [target]="fixedPopperLabel"
      [popperOptions]="labelPopperOptions"
      uiHighlight
      [content]="truncated()"
      [searchTerm]="currentFilter"
    ></div>
    <div #fixedPopperLabel class="popper">
      <mat-icon svgIcon="icon_tooltip"></mat-icon>
      <span>{{ all() }}</span>
    </div>
    <span
      *ngIf="!displayPopup"
      uiHighlight
      [content]="truncated()"
      [searchTerm]="currentFilter"
    ></span>
  `,
  styles: [
    `
      :host {
        .popper-target:hover + .popper {
          display: block;
        }

        .popper {
          display: none;

          span {
            display: block;
            height: 42px;
            line-height: 42px;
            padding: 0 20px;
            border-radius: 4px;
            z-index: 900;
            background: #5c5c5c;
            color: #ffffff;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
          }

          mat-icon {
            position: absolute;
            display: block;
            top: -17px;
            left: 45%;
            width: 20px;
            height: 32px;
            transform: rotate(90deg);
            z-index: -1;
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCellComponent {
  @Input() element: string[] = [];
  @Input() currentFilter = '';
  @Input() maxDisplayElements = 3;

  get displayPopup() {
    return this.element.length > this.maxDisplayElements;
  }

  labelPopperOptions: Partial<OptionsGeneric<StrictModifiers>> = {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-10, 10]
        }
      }
    ]
  };
  truncated() {
    return joinAndTruncateList(this.element, this.maxDisplayElements);
  }

  all() {
    return joinAndTruncateList(this.element, 0); // 0 means no limit
  }
}
