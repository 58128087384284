import { Directive, HostBinding, Input } from '@angular/core';

@Directive({ selector: '[onyxClaimEnabled]' })
export class ClaimEnabledButtonDirective {
  @Input()
  @HostBinding('disabled')
  disabled: boolean;

  public setDisabled() {
    this.disabled = true;
  }
}
