/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { StsDataAccessConfig } from '../sts-data-access-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserDetail } from '../models/user-detail';
import { UserSummary } from '../models/user-summary';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {
  constructor(config: StsDataAccessConfig, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/api/sts/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain$Response(params?: {
    textFilter?: string;
    isApprovedFilter?: boolean;
    zeroBasedPageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<Array<UserSummary>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.GetUsersPath,
      'get'
    );
    if (params) {
      rb.query('textFilter', params.textFilter);
      rb.query('isApprovedFilter', params.isApprovedFilter);
      rb.query('zeroBasedPageIndex', params.zeroBasedPageIndex);
      rb.query('pageSize', params.pageSize);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserSummary>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain(params?: {
    textFilter?: string;
    isApprovedFilter?: boolean;
    zeroBasedPageIndex?: number;
    pageSize?: number;
  }): Observable<Array<UserSummary>> {
    return this.getUsers$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<UserSummary>>) =>
          r.body as Array<UserSummary>
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Json$Response(params?: {
    textFilter?: string;
    isApprovedFilter?: boolean;
    zeroBasedPageIndex?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<Array<UserSummary>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.GetUsersPath,
      'get'
    );
    if (params) {
      rb.query('textFilter', params.textFilter);
      rb.query('isApprovedFilter', params.isApprovedFilter);
      rb.query('zeroBasedPageIndex', params.zeroBasedPageIndex);
      rb.query('pageSize', params.pageSize);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserSummary>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Json(params?: {
    textFilter?: string;
    isApprovedFilter?: boolean;
    zeroBasedPageIndex?: number;
    pageSize?: number;
  }): Observable<Array<UserSummary>> {
    return this.getUsers$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<UserSummary>>) =>
          r.body as Array<UserSummary>
      )
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/api/sts/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<UserDetail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.GetUserPath,
      'get'
    );
    if (params) {
      rb.path('userId', params.userId);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDetail>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Plain(params: { userId: string }): Observable<UserDetail> {
    return this.getUser$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetail>) => r.body as UserDetail)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<UserDetail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.GetUserPath,
      'get'
    );
    if (params) {
      rb.path('userId', params.userId);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDetail>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Json(params: { userId: string }): Observable<UserDetail> {
    return this.getUser$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetail>) => r.body as UserDetail)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/sts/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser$Json$Plain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Json$Plain$Response(params: {
    userId: string;

    body?: UserDetail;
  }): Observable<StrictHttpResponse<UserDetail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.UpdateUserPath,
      'put'
    );
    if (params) {
      rb.path('userId', params.userId);

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDetail>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Json$Plain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Json$Plain(params: {
    userId: string;

    body?: UserDetail;
  }): Observable<UserDetail> {
    return this.updateUser$Json$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetail>) => r.body as UserDetail)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Json$Json$Response(params: {
    userId: string;

    body?: UserDetail;
  }): Observable<StrictHttpResponse<UserDetail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.UpdateUserPath,
      'put'
    );
    if (params) {
      rb.path('userId', params.userId);

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserDetail>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Json$Json(params: {
    userId: string;

    body?: UserDetail;
  }): Observable<UserDetail> {
    return this.updateUser$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetail>) => r.body as UserDetail)
    );
  }

  /**
   * Path part for operation addTenantToUser
   */
  static readonly AddTenantToUserPath =
    '/api/sts/users/{userId}/tenants/{tenantKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantToUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTenantToUser$Response(params: {
    userId: string;
    tenantKey: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.AddTenantToUserPath,
      'put'
    );
    if (params) {
      rb.path('userId', params.userId);
      rb.path('tenantKey', params.tenantKey);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTenantToUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTenantToUser(params: {
    userId: string;
    tenantKey: string;
  }): Observable<void> {
    return this.addTenantToUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
