<h1 mat-dialog-title>{{ 'fleetmanager.reboot.title' | translate }}</h1>
<div mat-dialog-content>{{ 'fleetmanager.reboot.text' | translate }}</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" data-testid="reboot-cancel">
    {{ 'fleetmanager.reboot.cancel' | translate }}
  </button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    data-testid="reboot-confirm"
  >
    {{
      'fleetmanager.reboot.refresh' | translate: (countDownTranslation$ | async)
    }}
  </button>
</div>
