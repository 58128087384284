import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDto } from './models/notification-dto';

export const keyMap = {
  CancelTransport_NotFound: marker('notifications.transport.cancel.notFound'),
  CancelTransport_NotAllowed: marker(
    'notifications.transport.cancel.notAllowed'
  ),
  CancelTransport_Failed: marker('notifications.transport.cancel.failed'),
  CancelTransport_ThrewException: marker(
    'notifications.transport.cancel.exception'
  ),

  SuspendTransport_NotFound: marker('notifications.transport.suspend.notFound'),
  SuspendTransport_NotAllowed: marker(
    'notifications.transport.suspend.notAllowed'
  ),
  SuspendTransport_Failed: marker('notifications.transport.suspend.failed'),
  SuspendTransport_ThrewException: marker(
    'notifications.transport.suspend.exception'
  ),

  ChangeTransportPriority_NotFound: marker(
    'notifications.transport.priority.notFound'
  ),
  ChangeTransportPriority_Failed: marker(
    'notifications.transport.priority.failed'
  ),
  ChangeTransportPriority_ThrewException: marker(
    'notifications.transport.priority.exception'
  ),

  ReleaseTransport_NotFound: marker('notifications.transport.release.notFound'),
  ReleaseTransport_NotAllowed: marker(
    'notifications.transport.release.notAllowed'
  ),
  ReleaseTransport_Failed: marker('notifications.transport.release.failed'),
  ReleaseTransport_ThrewException: marker(
    'notifications.transport.release.exception'
  ),

  CreateTransport_ThrewException: marker(
    'notifications.transport.create.exception'
  ),
  Unknown: marker('notifications.unknown')
};

const maptoTranslationKey = (notificationKey: string) =>
  keyMap[notificationKey] ?? keyMap.Unknown;

@Injectable({ providedIn: 'root' })
export class NotificationTranslationService {
  constructor(private translateService: TranslateService) {}

  public toTranslatedMessage(notification: NotificationDto): string {
    const key = maptoTranslationKey(notification.translationKey ?? '');
    if (key === 'notifications.unknown')
      return this.translateService.instant(key, {
        message: notification.message
      });

    if (notification.exception)
      return this.translateService.instant(key, {
        exception: notification.exception
      });

    return this.translateService.instant(key);
  }
}
