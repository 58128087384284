export class OnyxEvents {
  public static readonly MicroFrontendLoaded = 'onyx-micro-frontend-loaded';
  public static readonly SelectedEntityId = 'onyx-selected-entity-id';
  public static readonly AuthToken = 'onyx-oidc-token';
  public static readonly UserData = 'onyx-oidc-userdata';
  public static readonly OnyxRouteChanged = 'onyx-route-changed';
  public static readonly MicroFrontendRouteChanged =
    'onyx-micro-frontend-route-changed';
  public static readonly LanguageChanged = 'onyx-language-changed';
  public static readonly SupportedLanguages = 'onyx-supported-languages';
}
