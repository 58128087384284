import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JobPanelsModule } from '../job-panels/job-panels.module';
import { PanelPickerComponent } from './panel-picker/panel-picker.component';

@NgModule({
  declarations: [PanelPickerComponent],
  exports: [PanelPickerComponent],
  imports: [CommonModule, JobPanelsModule]
})
export class PanelModule {}
