<div class="feature-overlay" fxLayout="column">
  <span class="close_btn" (click)="close()">
    <mat-icon svgIcon="icon_close"></mat-icon>
  </span>
  <div class="feature-content intro">
    <h3>{{ 'featureOverlay.title' | translate }}</h3>
    <p>{{ 'featureOverlay.text' | translate }}</p>
  </div>
  <div fxFlex class="feature-content items">
    <onyx-feature-list-item
      *ngFor="let feature of features$ | async"
      [feature]="feature"
      [enabled]="hasFeatureEnabled(feature.key) | async"
      (enabledChange)="toggleFeature(feature.key, $event)"
    >
    </onyx-feature-list-item>
  </div>
</div>
