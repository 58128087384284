import { Component, Input } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'onyx-loading-ellipsis',
  template: ` {{ dots$ | async }} `,
  styles: [
    `
      :host {
        width: 15px;
        display: inline-block;
        text-align: left;
      }
    `
  ]
})
export class LoadingEllipsisComponent {
  @Input() public speed = 750;
  public dots$: Observable<string>;
  public constructor() {
    this.dots$ = timer(0, this.speed).pipe(
      map(x => (x % 3) + 1),
      map(x =>
        new Array(x)
          .fill(undefined)
          .map(() => '.')
          .join('')
      )
    );
  }
}
