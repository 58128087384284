import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorCode, NatsError } from '@onyx/nats';

@Injectable()
export class OnyxErrorHandler implements ErrorHandler {
  public constructor(private snackbar: MatSnackBar, private zone: NgZone) {}
  public handleError(error: Error) {
    let message = 'An error occurred. Try again later.';
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        message = 'The requested resource could not be found.';
      } else if (error.status === 401 || error.status === 403) {
        message =
          'You are not authorized to access the requested resource. Sign in with a different account or contact your administrator.';
      } else if (error.status === 500) {
        message =
          'An error occurred on the server. Please try again later or contact support.';
      } else {
        message =
          'A network error occurred. Check your connection or try again later.';
      }
    }

    if (this.isNatsError(error)) {
      const natsError = error as NatsError;
      console.log({ ...natsError });
      if (natsError.isAuthError()) {
        message = 'Nats could not authenticate successfully';
      } else if (natsError.isPermissionError()) {
        message = `You do not have enough permissions because ${error.message}`;
      }
      if (
        natsError.code === ErrorCode.ConnectionClosed ||
        natsError.code === ErrorCode.ConnectionRefused ||
        natsError.code === ErrorCode.ConnectionTimeout
      ) {
        message = `We're having trouble connecting to Nats, please be patient`;
      }
    }
    // else if other specific messages
    this.zone.run(() =>
      this.snackbar.open(message, 'Dismiss', { duration: 5000 })
    );
    console.error(error);
  }

  private isNatsError(err: Error) {
    if (
      (err as NatsError).code &&
      (err as NatsError).isAuthError &&
      (err as NatsError).isPermissionError &&
      (err as NatsError).isProtocolError
    ) {
      return true;
    }
    return false;
  }
}
