{{ 'connection.trouble' | translate }}<br />
<table>
  <tr *ngFor="let connectionState of connections$ | async">
    <td>
      {{ getLabel(connectionState.key) }}
    </td>
    <td>
      <mat-icon *ngIf="connectionState.state === 'Connected'"
        >check_circle</mat-icon
      >
      <ng-container *ngIf="connectionState.state === 'Connecting'">
        {{ 'connection.reconnecting' | translate
        }}<onyx-loading-ellipsis></onyx-loading-ellipsis>
      </ng-container>
      <ng-container *ngIf="connectionState.state === 'Reconnecting'">
        {{ 'connection.reconnecting' | translate
        }}<onyx-loading-ellipsis></onyx-loading-ellipsis>
      </ng-container>
      <ng-container *ngIf="connectionState.state === 'Disconnected'">
        {{ 'connection.disconnected' | translate }}
      </ng-container>
    </td>
  </tr>
</table>
