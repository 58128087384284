import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ClaimAuthorizationService } from '@onyx/shared/authentication';
import {
  FeatureOverlayService,
  FeatureService
} from '@onyx/shared/feature-flags';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  LAST_VISITED_AGV_STORAGE_KEY,
  MonitorService
} from '../monitor.service';
import { NavigationItem } from '../nav-item/navigation-item';
import { SIDENAV_ITEMS } from './sidenav-items-token';

@Component({
  selector: 'onyx-root-navigation',
  templateUrl: './root-navigation.component.html',
  exportAs: 'nav'
})
export class RootNavigationComponent implements OnDestroy, OnInit {
  @ViewChild(MatSidenav) public drawer: MatSidenav;
  @Input() public isHandset: boolean;
  @Input() public isLandscape: boolean;
  @Input() public isMenuOpen = false;
  @Input() public showNavBar: boolean;
  @Output() public isMenuOpenChange = new EventEmitter<boolean>();
  public navDesktopOpen = false;
  public _ = marker;
  navigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => event as NavigationEnd)
  );
  private destroy$ = new Subject();
  currentUrl$ = this.navigationEnd$.pipe(map(event => event.urlAfterRedirects));
  public navigationListItems$: Observable<NavigationItem[]>;
  public navigationListItemsBottom$: Observable<NavigationItem[]>;
  public hasMultipleTenants$: Observable<boolean>;
  public lastVisitedAgv$: Observable<string>;

  public STATIC_NAVITEMS: {
    [key: string]: { svgIcon: boolean; iconName: string; path?: string };
  } = {
    monitor: {
      svgIcon: true,
      iconName: 'icon_monitor'
    },
    switchTenant: {
      svgIcon: true,
      iconName: 'icon_tenant',
      path: '../tenant'
    },
    settings: {
      svgIcon: true,
      iconName: 'icon_settings',
      path: 'settings'
    },
    sidenavToggle: {
      svgIcon: true,
      iconName: 'icon_toggle'
    },
    signout: {
      svgIcon: true,
      iconName: 'icon_user'
    }
  };

  constructor(
    private router: Router,
    private featureOverlayService: FeatureOverlayService,
    private oidcSecurityService: OidcSecurityService,
    private featureService: FeatureService,
    private claimsAuthorizationService: ClaimAuthorizationService,
    @Inject(SIDENAV_ITEMS) private allNavigationListItems: NavigationItem[],
    monitorService: MonitorService
  ) {
    this.lastVisitedAgv$ = monitorService.lastVisitedAgv$;
    this.lastVisitedAgv$
      .pipe(
        filter(x => !!x),
        takeUntil(this.destroy$)
      )
      .subscribe(x => localStorage.setItem(LAST_VISITED_AGV_STORAGE_KEY, x));
  }

  public ngOnInit() {
    const visibleNavigationItems$ =
      this.featureService.allEnabledFeatureKeys$.pipe(
        map(x =>
          this.allNavigationListItems.filter(
            y => !y.feature || x.includes(y.feature)
          )
        ),
        switchMap(navItems =>
          this.claimsAuthorizationService.filterByClaims(navItems)
        ),
        map(filtered => filtered as NavigationItem[])
      );

    const nonVehicleNavigationListItems$ = visibleNavigationItems$.pipe(
      map(x => x.filter(n => !n.navVehicle))
    );

    this.navigationListItems$ = nonVehicleNavigationListItems$.pipe(
      map(x => x.filter(i => i.navTop))
    );
    this.navigationListItemsBottom$ = nonVehicleNavigationListItems$.pipe(
      map(x => x.filter(i => !i.navTop))
    );
  }

  async open() {
    if (this.drawer) await this.drawer.open();
    this.toggleMenu(true);
  }

  async close() {
    if (this.drawer) await this.drawer.close();
    this.toggleMenu(false);
  }

  closeIfHandset() {
    if (this.isHandset) {
      this.close();
    }
  }

  public signOut() {
    this.oidcSecurityService.logoff();
  }

  public async openMonitor() {
    const lastVisitedAgv = await this.lastVisitedAgv$.pipe(take(1)).toPromise();
    this.router.navigate(['agv', lastVisitedAgv, 'display']);

    this.closeIfHandset();
  }

  toggleMenu(toggle: boolean) {
    this.isMenuOpen = toggle;
    this.isMenuOpenChange.emit(toggle);
  }

  public ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  public openFeaturesOverlay() {
    this.featureOverlayService.openFeaturesOverlay();
  }
}
