import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Environment, ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';
import {
  AuthenticatedResult,
  OidcSecurityService
} from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {}

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.isLoggedIn(state);
  }

  public canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.isLoggedIn(state);
  }

  private isLoggedIn(
    routerStateSnapshot: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const currentUrl = routerStateSnapshot.url;

    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((result: AuthenticatedResult) => {
        // ignore auth for dev
        if (!this.environment.useAuthentication) {
          return true;
        }
        if (result.isAuthenticated) {
          return true;
        }
        sessionStorage['oidc_redirect_uri'] = currentUrl;
        return this.router.createUrlTree(['/auto-login']);
      }),
      take(1)
    );
  }
}
