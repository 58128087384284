import { LOCALE_ID, StaticClassProvider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * A locale provider based on the current selected language
 */
export class LanguageLocaleId extends String {
  public constructor(protected service: TranslateService) {
    super();
  }
  public toString() {
    return this.service.currentLang;
  }
}

export const localeProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: LanguageLocaleId,
  deps: [TranslateService]
};
