import { CommonModule } from '@angular/common';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@onyx/core';
import { CustomMaterialModule } from './custom-material/custom-material.module';

export * from './custom-material/custom-material.module';

@NgModule({
  exports: [
    CommonModule,
    CustomMaterialModule,
    RouterTestingModule,
    HttpClientTestingModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    TranslateModule,
    IconsModule
  ],
  imports: [TranslateModule.forRoot()]
})
export class TestingImportsModule {}
