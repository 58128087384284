import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  firstValueFrom
} from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { Feature } from './feature';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private readonly LocalStorageKey = 'onyx:features:enabled';
  private enabledExperimentalFeatureKeys$: ReplaySubject<string[]>;
  private defaultEnabledFeatureKeys$: Observable<string[]>;

  public readonly allExperimentalFeatures$: Observable<Feature[]>;
  public readonly allEnabledFeatureKeys$: Observable<string[]>;

  constructor() {
    const features$ = from<Feature[][]>([
      [
        {
          key: 'system-overview',
          label: 'features.systemoverview.label',
          description: 'features.systemoverview.description',
          isExperimental: false
        },
        {
          key: 'transports',
          label: 'features.transport.label',
          description: 'features.transport.description'
        },
        {
          key: 'agv',
          label: 'features.agv.label',
          description: 'features.agv.description',
          isExperimental: false
        },
        {
          key: 'multi-language',
          label: 'features.multiLanguage.label',
          description: 'features.multiLanguage.description',
          isExperimental: false
        },
        {
          key: 'locations',
          label: 'features.locations.label',
          description: 'features.locations.description',
          isExperimental: false
        },
        {
          key: 'location-management',
          label: '',
          description: '',
          isExperimental: false
        }
      ]
    ]);

    this.defaultEnabledFeatureKeys$ = features$.pipe(
      map(features => features.filter(f => !f.isExperimental).map(f => f.key))
    );
    this.allExperimentalFeatures$ = features$.pipe(
      map(features => features.filter(f => f.isExperimental))
    );

    this.enabledExperimentalFeatureKeys$ = new ReplaySubject<string[]>(1);
    this.enabledExperimentalFeatureKeys$.pipe(skip(1)).subscribe({
      next: next => this.persist(next)
    });

    this.restore();

    this.allEnabledFeatureKeys$ = combineLatest([
      this.enabledExperimentalFeatureKeys$,
      this.defaultEnabledFeatureKeys$
    ]).pipe(
      map(([enabledExperimentalFeatureKeys, defaultFeatureKeys]) => [
        ...enabledExperimentalFeatureKeys,
        ...defaultFeatureKeys
      ])
    );
  }

  public hasFeatureEnabled(featureKey: string): Observable<boolean> {
    return this.allEnabledFeatureKeys$.pipe(
      map(keys => featureKey === '' || keys.indexOf(featureKey) >= 0)
    );
  }

  public async setExperimentalFeatureState(
    featureKey: string,
    enabled: boolean
  ) {
    const enabledFeatureKeys = await firstValueFrom(
      this.enabledExperimentalFeatureKeys$
    );
    const index = enabledFeatureKeys.indexOf(featureKey);
    if (enabled && index === -1) {
      this.enabledExperimentalFeatureKeys$.next([
        ...enabledFeatureKeys,
        featureKey
      ]);
    } else if (!enabled && index >= 0) {
      this.enabledExperimentalFeatureKeys$.next(
        enabledFeatureKeys.filter(key => key !== featureKey)
      );
    }
  }

  private persist(next: string[]) {
    const serialized = JSON.stringify(next);
    localStorage.setItem(this.LocalStorageKey, serialized);
  }

  private restore() {
    const serialized = localStorage.getItem(this.LocalStorageKey);
    if (serialized) {
      const enabledKeys = JSON.parse(serialized) as string[];
      // We only want to enable the features from localstorage that are supported by the current tenant
      this.allExperimentalFeatures$.subscribe(experimentalFeatures => {
        const enabledExperimentalFeatureKeys = experimentalFeatures
          .filter(f => enabledKeys.includes(f.key))
          .map(f => f.key);
        this.enabledExperimentalFeatureKeys$.next(
          enabledExperimentalFeatureKeys
        );
      });
    } else {
      this.enabledExperimentalFeatureKeys$.next([]);
    }
  }
}
