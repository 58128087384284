import { Injectable } from '@angular/core';
import { MicroFrontendsService } from './generated/services';

/**
 * a shared loader for external javascript modules or script files.
 * This should enable us to easily check in a common way if a module has been loaded before
 */
@Injectable({ providedIn: 'root' })
export class CustomComponentLoaderService {
  constructor(private microFrontendService: MicroFrontendsService) {}
  private static loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const node = document.createElement('script');
      node.src = url;
      node.async = true;
      node.onerror = err => reject(err);
      node.onload = () => resolve();
      document.head.appendChild(node);
    });
  }
  async loadMenuItem(component: string): Promise<string> {
    if (!customElements.get(component)) {
      const script = await this.microFrontendService
        .mainMenuBundle({ id: component })
        .toPromise();
      await CustomComponentLoaderService.loadScript(script);
    }
    await customElements.whenDefined(component);
    return component;
  }
  async loadSystemOverviewPanel(component: string): Promise<string> {
    if (!customElements.get(component)) {
      const script = await this.microFrontendService
        .systemOverviewPanelBundle({ id: component })
        .toPromise();
      await CustomComponentLoaderService.loadScript(script);
    }
    await customElements.whenDefined(component);

    return component;
  }
}

/**
 * Mock version of the CustomComponentLoaderService
 */
export class CustomComponentLoaderServiceMock {
  async loadMenuItem(component: string) {
    // registering customElements needs to be unique both by name and constructor
    // until actual issues arise, leave stubbed/empty
    return component;
  }
}
