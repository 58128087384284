import { Injectable } from '@angular/core';
import { ConnectionState, NatsConnectionMonitor } from '@onyx/ngx-nats';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, scan, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionMessageService {
  public connectionState$: Observable<{
    hasFaultyConnections: boolean;
    hasConnections: boolean;
    previousHadFaulty: boolean;
  }>;

  public constructor(natsConnectionMonitor: NatsConnectionMonitor) {
    this.connectionState$ = natsConnectionMonitor.activeConnections$.pipe(
      map(x => {
        const hasConnections = x.length > 0;
        const hasFaultyConnections = x.some(
          y => y.state !== ConnectionState.Connected
        );
        return {
          hasConnections,
          hasFaultyConnections,
          previousHadFaulty: undefined
        };
      }),
      scan<
        {
          hasFaultyConnections: boolean;
          hasConnections: boolean;
          previousHadFaulty: boolean;
        },
        {
          hasFaultyConnections: boolean;
          hasConnections: boolean;
          previousHadFaulty: boolean;
        }
      >((prev, current) => ({
        hasConnections: current.hasConnections,
        hasFaultyConnections: current.hasFaultyConnections,
        previousHadFaulty: prev ? prev.hasFaultyConnections : false
      })),
      distinctUntilChanged(
        (x, y) =>
          x.hasConnections === y.hasConnections &&
          x.hasFaultyConnections === y.hasFaultyConnections &&
          x.previousHadFaulty === y.previousHadFaulty
      ),
      shareReplay(1)
    );
  }
}
