/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { StsDataAccessConfig } from '../sts-data-access-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MeDetails } from '../models/me-details';

@Injectable({
  providedIn: 'root'
})
export class MeService extends BaseService {
  constructor(config: StsDataAccessConfig, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/api/sts/Me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params?: {}): Observable<StrictHttpResponse<MeDetails>> {
    const rb = new RequestBuilder(this.rootUrl, MeService.GetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<MeDetails>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params?: {}): Observable<MeDetails> {
    return this.get$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MeDetails>) => r.body as MeDetails)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Json$Response(params?: {}): Observable<StrictHttpResponse<MeDetails>> {
    const rb = new RequestBuilder(this.rootUrl, MeService.GetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<MeDetails>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Json(params?: {}): Observable<MeDetails> {
    return this.get$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeDetails>) => r.body as MeDetails)
    );
  }
}
