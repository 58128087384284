import { OverlayRef } from '@angular/cdk/overlay';
import { mapTo } from 'rxjs/operators';

export class FeatureOverlayRef {
  public constructor(private overlayRef: OverlayRef) {}
  public closings() {
    return this.overlayRef.detachments().pipe(mapTo(this));
  }
  public close() {
    this.overlayRef.dispose();
  }
}
