<ng-container *ngIf="changelog | async as changelog; else loading">
  <section class="current-version">
    <div class="container">
      <span class="title">{{ 'settings.changelog.version' | translate }}</span>
      <span class="version" data-testid="changelog-version">{{
        currentVersion$ | async
      }}</span>
    </div>
  </section>

  <onyx-changelog-entry *ngFor="let entry of changelog" [entry]="entry">
  </onyx-changelog-entry>
</ng-container>

<ng-template #loading>
  <onyx-spinner> </onyx-spinner>
</ng-template>
