import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: `
    <h1>{{ 'notfound.title' | translate }}</h1>
    <p>{{ 'notfound.text' | translate }}</p>
    <button mat-button (click)="goHome()">
      {{ 'notfound.goHomeBtn' | translate }}
    </button>
  `,
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-flow: column;
      }
      h1 {
        font-weight: 100;
        color: #777777;
      }
      p {
        font-size: 20px;
        color: #999999;
      }
    `
  ]
})
export class NotFoundComponent {
  public constructor(private router: Router) {}

  public goHome() {
    this.router.navigate(['/']);
  }
}
