<div class="load" *ngIf="loadingTenant$ | async; else tenantApplication">
  <onyx-spinner></onyx-spinner>
</div>
<ng-template #tenantApplication>
  <mat-toolbar *ngIf="showNavBar$ | async">
    <div class="header-container">
      <div class="left-icons mobile" *onyxResponsive="['handset']">
        <div
          class="nav-icon"
          (click)="openMobileMenu()"
          [class.open]="menuOpen"
          data-cy="mobile-side-nav-toggle"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <span id="logo_placeholder"></span>
      <div class="right-icons desktop" data-cy="profile">
        <div class="input-container" *onyxFeature="'search'">
          <input type="search" />
          <mat-icon svgIcon="icon_search"></mat-icon>
        </div>
        <span
          (click)="popup()"
          *onyxResponsive="['handset']; inverse: true"
          [matTooltip]="'menu.popup' | translate"
        >
          <mat-icon>open_in_new</mat-icon>
        </span>
        <span class="profile" [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="icon_user"></mat-icon>
        </span>
      </div>
    </div>
  </mat-toolbar>
  <onyx-root-navigation
    class="desktop"
    #nav="nav"
    [isHandset]="isHandset$ | async"
    [(isMenuOpen)]="menuOpen"
    [showNavBar]="showNavBar$ | async"
  >
    <router-outlet></router-outlet>
  </onyx-root-navigation>
  <mat-menu class="profile-menu" #menu="matMenu">
    <onyx-profile-popup></onyx-profile-popup>
  </mat-menu>
</ng-template>
