<!-- navigation item with a known path -->
<a
  *ngIf="!navItem.children && navItem.path"
  [routerLink]="navItem.path"
  routerLinkActive="active"
  [queryParams]="navItem.queryParams"
  [attr.data-cy-id]="navItem.path"
  class="onyx-nav-item"
  uiPopper
  [target]="fixedPathLinkLabel"
  [popperOptions]="labelPopperOptions"
  [shouldPop]="!showText"
>
  <div class="nav-icon">
    <mat-icon *ngIf="navItem.svgIcon" [svgIcon]="navItem.iconName"></mat-icon>
    <mat-icon *ngIf="!navItem.svgIcon">{{ navItem.iconName }}</mat-icon>
  </div>
  <span #fixedPathLinkLabel class="text" [class.collapsed]="!showText">{{
    text | translate
  }}</span>
</a>

<!-- navigation item without a path, i.e. collapse/expand toggle or with a dynamic path like agv monitor -->
<a
  *ngIf="!navItem.children && !navItem.path"
  class="onyx-nav-item"
  uiPopper
  [popperOptions]="labelPopperOptions"
  [target]="dynamicPathLinkLabel"
  [shouldPop]="!showText"
>
  <div class="nav-icon">
    <mat-icon *ngIf="navItem.svgIcon" [svgIcon]="navItem.iconName"></mat-icon>
    <mat-icon *ngIf="!navItem.svgIcon">{{ navItem.iconName }}</mat-icon>
  </div>
  <span #dynamicPathLinkLabel class="text" [class.collapsed]="!showText">{{
    text | translate
  }}</span>
</a>

<!-- navigation item with children (sub navigation) -->
<ng-container *ngIf="navItem.children">
  <div class="toggle-container" (click)="toggleSubnav()">
    <mat-icon class="toggle" svgIcon="icon_arrow_small"></mat-icon>
  </div>
  <a
    [routerLink]="navItem.path"
    routerLinkActive="active"
    [queryParams]="navItem.queryParams"
    [attr.data-cy-id]="navItem.path"
    uiPopper
    [target]="shouldPopSubMenu ? subMenu : subMenuTitle"
    [popperOptions]="
      shouldPopSubMenu ? sideNavPopperOptions : labelPopperOptions
    "
    [shouldPop]="!showText"
    [allowHoverOverTarget]="shouldPopSubMenu"
  >
    <div class="nav-icon">
      <div>
        <mat-icon
          *ngIf="navItem.svgIcon"
          [svgIcon]="navItem.iconName"
        ></mat-icon>
        <mat-icon *ngIf="!navItem.svgIcon">{{ navItem.iconName }}</mat-icon>
      </div>
    </div>
    <span class="text" #subMenuTitle>{{ text | translate }}</span>
  </a>
  <div
    class="subnav"
    [class.collapsed]="!showText && !isChildRouteActive()"
    #subMenu
  >
    <h3>{{ text | translate }}</h3>
    <ng-container *ngFor="let subnavItem of navItem.children">
      <a
        [routerLink]="subnavItem.path"
        routerLinkActive="active"
        [queryParams]="subnavItem.queryParams"
        [attr.data-cy-id]="subnavItem.path"
        uiPopper
        [popperOptions]="labelPopperOptions"
        [target]="subNavItemLinkLabel"
        [shouldPop]="!shouldPopSubMenu && !showText"
        [allowHoverOverTarget]="false"
      >
        <div class="subnav-icon">
          <mat-icon
            *ngIf="subnavItem.svgIcon"
            [svgIcon]="subnavItem.iconName"
          ></mat-icon>
          <mat-icon *ngIf="!subnavItem.svgIcon">{{
            subnavItem.iconName
          }}</mat-icon>
        </div>
        <span #subNavItemLinkLabel>{{
          subnavItem.description | translate
        }}</span>
      </a>
    </ng-container>
  </div>
</ng-container>
