import { Inject, Injectable } from '@angular/core';
import { OnyxEventService } from './onyx-event-service';
import { EVENT_SERVICES } from './onyx-event-services-token';

@Injectable()
export class OnyxEventInitializerService {
  constructor(@Inject(EVENT_SERVICES) private services: OnyxEventService[]) {}
  public init() {
    this.services.forEach(s => s.initialize());
  }
}
