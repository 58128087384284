import { Component, OnDestroy } from '@angular/core';
import { Claims } from '@onyx/shared/authentication';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'onyx-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnDestroy {
  userData$: any;
  private destroy$ = new Subject<void>();
  isExternallyAuthenticated: boolean;

  constructor(public oidcSecurityService: OidcSecurityService) {
    this.userData$ = this.oidcSecurityService.userData$.pipe(
      map(d => d.userData),
      takeUntil(this.destroy$)
    );
    this.isExternallyAuthenticated = (
      this.oidcSecurityService.getPayloadFromIdToken().amr as string[]
    )?.some(a => a === 'external');
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  claimsInProfile(profile: any): string[] {
    return Object.keys(profile)
      .filter(k => Object.values<string>(Claims).includes(k))
      .map(claim => this.getClaimsTranslationKey(claim as Claims));
  }

  private getClaimsTranslationKey(claim: Claims) {
    if (Claims.OffboardVehicleActions === claim) {
      return `claims.${claim}.other`;
    }
    return `claims.${claim}`;
  }

  signOut() {
    this.oidcSecurityService.logoffAndRevokeTokens('oidc').subscribe();
  }

  changePassword() {
    const changePasswordUrl =
      //we do some educated gambling here since we technically can't know if this
      //url is the provider or just an intermediary...
      //For our use case it will likely always point to the identityserver we manage!
      this.oidcSecurityService.getConfiguration('oidc')
        .authWellknownEndpointUrl +
      `/Identity/ChangePassword?returnUrl=${encodeURIComponent(
        window.location.toString()
      )}`;

    window.location.assign(changePasswordUrl);
  }
}
