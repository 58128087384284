import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationResult } from './confirmation-result';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ConfirmationService {
  public constructor(private matDialog: MatDialog) {}

  public async openDialog(message: string = null) {
    const confirmationDialogRef = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        data: { message }
      }
    );
    return lastValueFrom<ConfirmationResult>(
      confirmationDialogRef.afterClosed()
    );
  }
}
