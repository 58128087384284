import { Injectable, Inject } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { Environment, ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {
  public checkForUpdateTrigger$: Observable<number | boolean>;

  constructor(@Inject(ENVIRONMENT_TOKEN) environment: Environment) {
    const updateFrequency = environment.update_frequency || 30;
    this.checkForUpdateTrigger$ = interval(updateFrequency * 1000);
  }
}
