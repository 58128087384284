import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ui-details-drawer-title',
  template: `<h2><ng-content></ng-content></h2>`,
  styles: [
    `
    h2 {
      font-size: 25px;
      font-weight: 500;
      padding-bottom: 12px;
      border-bottom: 1px solid
    }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsDrawerTitleComponent{}
