import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'onyx-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}
  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe(result => {
      if (result.isAuthenticated) {
        const returnUrl = sessionStorage['oidc_redirect_uri'];
        if (returnUrl) {
          sessionStorage.removeItem('oidc_redirect_uri');
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigateByUrl('/');
        }
      }
    });
  }
}
