import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export enum Claims {
  OffboardTransportEdit = 'onyx.offboard.transport.edit',
  OffboardTransportRead = 'onyx.offboard.transport.read',
  OffboardLocationEdit = 'onyx.offboard.location.edit',
  OffboardLocationRead = 'onyx.offboard.location.read',
  OffboardSignalEdit = 'onyx.offboard.signal.edit',
  OffboardSignalRead = 'onyx.offboard.signal.read',
  OffboardVehicleRead = 'onyx.offboard.vehicle.read',
  OffboardVehicleActions = 'onyx.offboard.vehicle.actions',
  OffboardVehicleActionRemove = 'onyx.offboard.vehicle.actions.remove',
  OffboardJobRead = 'onyx.offboard.job.read',
  OffboardJobEdit = 'onyx.offboard.job.edit',
  ManageAccount = 'onyx.account'
}

marker('unauthorized.permissions.onyx.offboard.transport.edit');
marker('unauthorized.permissions.onyx.offboard.transport.read');
marker('unauthorized.permissions.onyx.offboard.location.edit');
marker('unauthorized.permissions.onyx.offboard.location.read');
marker('unauthorized.permissions.onyx.offboard.signal.edit');
marker('unauthorized.permissions.onyx.offboard.signal.read');
marker('unauthorized.permissions.onyx.offboard.vehicle.read');
marker('unauthorized.permissions.onyx.offboard.vehicle.actions.other');
marker('unauthorized.permissions.onyx.offboard.vehicle.actions.remove');
marker('unauthorized.permissions.onyx.account');

marker('claims.onyx.offboard.transport.edit');
marker('claims.onyx.offboard.transport.read');
marker('claims.onyx.offboard.location.edit');
marker('claims.onyx.offboard.location.read');
marker('claims.onyx.offboard.signal.edit');
marker('claims.onyx.offboard.signal.read');
marker('claims.onyx.offboard.vehicle.read');
marker('claims.onyx.offboard.vehicle.actions.other');
marker('claims.onyx.offboard.vehicle.actions.remove');
marker('claims.onyx.account');
