import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { ResponsiveService } from './responsive.service';
import { Subject, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[onyxResponsive]'
})
export class ResponsiveDirective implements OnChanges, OnDestroy {
  @Input('onyxResponsive') public requestedFlags: string[] = [];
  @Input('onyxResponsiveInverse') public inverse = false;
  private readonly onDestroy$ = new Subject();
  private readonly requestedFlags$ = new Subject<string[]>();
  private shown = false;
  public constructor(
    responsiveService: ResponsiveService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    combineLatest([responsiveService.responsiveFlags$, this.requestedFlags$])
      .pipe(
        map(([flags, requestedFlags]) => {
          const currentFlags = Object.getOwnPropertyNames(flags).filter(
            x => flags[x]
          );
          const containsFlags = requestedFlags.every(f =>
            currentFlags.includes(f)
          );
          // xor with inverse
          return containsFlags ? !this.inverse : this.inverse;
        }),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(show => {
        if (show && !this.shown) {
          this.shown = true;
          viewContainerRef.createEmbeddedView(templateRef);
        } else if (!show && this.shown) {
          this.shown = false;
          viewContainerRef.clear();
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.requestedFlags) {
      this.requestedFlags$.next(this.requestedFlags);
    }
  }
  public ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
