import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

// For more info, see also: https://github.com/angular/components/issues/5573
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate
      .get([
        'paginator.ItemsPerPage',
        'paginator.NextPage',
        'paginator.PreviousPage',
        'paginator.FirstPage',
        'paginator.LastPage'
      ])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['paginator.ItemsPerPage'];
        this.nextPageLabel = translation['paginator.NextPage'];
        this.previousPageLabel = translation['paginator.PreviousPage'];
        this.firstPageLabel = translation['paginator.FirstPage'];
        this.lastPageLabel = translation['paginator.LastPage'];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
