/* eslint-disable @typescript-eslint/naming-convention */
import { Environment } from '@onyx/shared/common-imports';

export const environment: Environment = {
  production: true,
  oidc_authority: '.',
  oidc_clientid: 'onyx-clientapp',
  oidc_postlogin_path: 'oidc-callback',
  oidc_postlogout_path: '',
  oidc_scope: 'openid profile email onyxapi offline_access nats role role-api',
  oidc_responsetype: 'code',
  tracer_service_name: 'Onyx.ClientApp',
  jaeger_span_endpoint: 'jaeger/api/v2/spans',
  update_frequency: 60, // check for an update of the client every minute
  update_auto_refresh_delay: 30, // give the user half a minute to respond to the update modal
  useAuthentication: true,
  nats_uri: '',
  brand: 'kion'
};
