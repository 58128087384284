import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ui-details-page',
  template: `
    <mat-drawer-container class="container">
      <mat-drawer
        #drawer
        mode="side"
        position="end"
        [opened]="drawerOpenend"
        class="drawer"
      >
        <mat-icon
          class="close"
          svgIcon="icon_arrow_line"
          (click)="closeDrawer.emit()"
        ></mat-icon>
        <div class="drawerContainer">
          <ng-content select="[drawer]"></ng-content>
        </div>
      </mat-drawer>
      <mat-drawer-content class="page">
        <div class="pageContainer">
          <ng-content select="[page]"></ng-content>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  `,
  styles: [
    `
      .container {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .close {
        position: absolute;
        right: 20px;
        top: 5px;
        transform: rotate(-180deg);
        cursor: pointer;
        transition: all 220ms ease-in-out;
      }
      .drawer {
        width: 400px;
        overflow-y: auto;
        right: 0;
        box-shadow: -10px 0 32px 0 rgba(123, 123, 123, 0.3);
        flex:1;
        
      }
      .drawerContainer {
        margin-top: 35px;
        margin-left: 20px;
        margin-right: 20px;
        flex:1;
      }
      .page{
        overflow-y:auto;
        left:0;
        flex:1;
      }
      .pageContainer {
        padding: 20px;
        flex:1;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsPageComponent {
  @Input() drawerOpenend = false;
  @Output() closeDrawer = new EventEmitter<any>();
}
