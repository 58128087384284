import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storageChanged$: { [index: string]: Subject<string> } = {};

  public listenForChanges(key: string) {
    const initialValue: string = localStorage.getItem(key);
    this.storageChanged$[key] = new Subject<string>();
    const storageEvent = fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(x => x.key === key),
      map(x => x.newValue)
    );
    return merge(of(initialValue), storageEvent, this.storageChanged$[key]);
  }

  public setItem(key: string, value: string) {
    localStorage.setItem(key, value);
    this.storageChanged$[key].next(value);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
    this.storageChanged$[key].next(null);
  }
}
