import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable()
export class ResponsiveService {
  public responsiveFlags$: Observable<{
    handset: boolean;
    tablet: boolean;
    landscape: boolean;
  }>;

  public constructor(breakpointObserver: BreakpointObserver) {
    const isLandscape$ = breakpointObserver
      .observe([
        Breakpoints.WebLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetLandscape
      ])
      .pipe(map(x => x.matches));
    const isHandset$ = breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(x => x.matches));
    const isTablet$ = breakpointObserver
      .observe(Breakpoints.Tablet)
      .pipe(map(x => x.matches));
    this.responsiveFlags$ = combineLatest([
      isHandset$,
      isTablet$,
      isLandscape$
    ]).pipe(
      map(([handset, tablet, landscape]) => ({ handset, tablet, landscape }))
    );
  }
}
