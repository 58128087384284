import { Component } from '@angular/core';
import { ClaimAuthorizationService, Claims } from '@onyx/shared/authentication';
import { ResponsiveService } from '@onyx/shared/common';
import { FeatureOverlayService } from '@onyx/shared/feature-flags';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Setting } from '../settings';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'onyx-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
  public settings$: Observable<Setting[]>;
  public isHandset$: Observable<boolean>;
  public canAccessUserManagement$: Observable<boolean>;

  constructor(
    settingsService: SettingsService,
    responsiveService: ResponsiveService,
    private featuresOverlayService: FeatureOverlayService,
    private claimAuthorizationService: ClaimAuthorizationService
  ) {
    this.settings$ = settingsService.settings$;
    this.isHandset$ = responsiveService.responsiveFlags$.pipe(
      map(x => x.handset)
    );
    this.canAccessUserManagement$ = this.claimAuthorizationService.userHasClaim(
      true,
      [Claims.ManageAccount]
    );
  }

  public openFeaturesOverlay() {
    this.featuresOverlayService.openFeaturesOverlay();
  }
}
