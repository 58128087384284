import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ui-toggle-cell',
  template: `
    <mat-slide-toggle
      color="primary"
      [checked]="checked"
      (click)="$event.stopPropagation()"
      (change)="checkedChange.emit($event.checked)"
      [disabled]="disabled"
    >
      <span uiHighlight [content]="label" [searchTerm]="currentFilter"></span>
    </mat-slide-toggle>
  `,
  styles: [
    `
      :host {
        .mat-slide-toggle-label {
          display: flex;
          flex-direction: row !important;
          min-height: auto;

          .mat-slide-toggle-bar {
            order: 1;
            margin-right: 15px;
          }
          .mat-slide-toggle-content {
            order: 2;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleCellComponent {
  @Input() currentFilter = '';

  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  @Input() disabled = false;

  @Input() label = '';
}
