import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomComponentEventsService } from '../custom-component-events.service';
import { OnyxEventService } from '../onyx-event-service';
import { OnyxEvents } from '../onyx-events';

@Injectable()
export class LanguageChangeService implements OnyxEventService {
  constructor(
    private eventService: CustomComponentEventsService,
    private translate: TranslateService
  ) {}
  initialize(): void {
    this.eventService
      .onMicroFrontendLoaded(this.translate.onLangChange)
      .subscribe(lang =>
        this.eventService.publishEvent(OnyxEvents.LanguageChanged, lang.lang)
      );
  }
}
