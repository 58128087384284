import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Claims } from '..';

export interface ItemWithClaims {
  claims?: Claims[];
}

@Injectable({
  providedIn: 'root'
})
export class ClaimAuthorizationService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {}

  public filterByClaims(
    itemsToFilter: ItemWithClaims[],
    atLeastOneClaim: boolean = true
  ): Observable<ItemWithClaims[]> {
    if (!this.environment.useAuthentication) return from([itemsToFilter]);
    return combineLatest([
      this.oidcSecurityService.userData$.pipe(map(ud => ud?.userData)),
      of(itemsToFilter)
    ]).pipe(
      map(([profile, items]) =>
        items.filter(
          item =>
            !item.claims || this.hasClaim(profile, atLeastOneClaim, item.claims)
        )
      )
    );
  }

  public userHasClaim(
    atLeastOneClaim: boolean,
    requiredClaims: string[]
  ): Observable<boolean> {
    if (!this.environment.useAuthentication) return from([true]);
    return this.oidcSecurityService.userData$.pipe(
      map(ud => ud.userData),
      map(profile => this.hasClaim(profile, atLeastOneClaim, requiredClaims))
    );
  }

  private hasClaim(
    profile: any,
    atLeastOneClaim: boolean,
    requiredClaims: string[]
  ): boolean {
    if (!this.environment.useAuthentication) return true;
    if (!profile) return false;
    if (atLeastOneClaim) {
      return requiredClaims.some(rc =>
        Object.keys(profile).find(userClaim => userClaim === rc)
      );
    } else {
      return requiredClaims.every(rc =>
        Object.keys(profile).find(userClaim => userClaim === rc)
      );
    }
  }
}
