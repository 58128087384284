import { NgModule } from '@angular/core';
import { FeatureOverlayService } from './feature-overlay.service';
import { FeatureService } from './feature.service';
import { FeatureDummyService } from './feature.service.mock';
import { FeatureOverlayServiceMock } from './feature-overlay.service.mock';
import { CommonModule } from '@angular/common';

export { FeatureOverlayServiceMock, FeatureDummyService };

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [
    { provide: FeatureService, useClass: FeatureDummyService },
    { provide: FeatureOverlayService, useClass: FeatureOverlayServiceMock }
  ]
})
export class SharedFeatureFlagsTestingModule {}
