import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationsInitiliazerService } from './translations-initiliazer.service';

export function httpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/i18n/', suffix: '.json' },
    {
      prefix: `assets/i18n/vehicle-status/`,
      suffix: '.json'
    }
  ]);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
      useDefaultLang: true
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (_init: TranslationsInitiliazerService) => () => {
        //just having the translationservice initiliazed is good enough, no need to call anything
      },
      deps: [TranslationsInitiliazerService]
    }
  ]
})
export class TranslationsModule {}
