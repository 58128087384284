import { Injectable } from '@angular/core';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnyxEvents } from './onyx-events';

@Injectable({ providedIn: 'root' })
export class CustomComponentEventsService {
  onMicroFrontendLoaded<T>(observable: Observable<T>) {
    return combineLatest([
      observable,
      fromEvent(window, OnyxEvents.MicroFrontendLoaded)
    ]).pipe(map(([value]) => value));
  }

  publishEvent(eventType: string, eventData: any) {
    const event = new CustomEvent(eventType, {
      bubbles: true,
      cancelable: false,
      detail: eventData
    });
    window.dispatchEvent(event);
  }
}
