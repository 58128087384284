import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Environment, ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';
import { map, Observable } from 'rxjs';
import { ClaimAuthorizationService } from './claim-authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate, CanActivateChild {
  onyxRequiredClaimOr = false;
  constructor(
    private claimAuthorizationService: ClaimAuthorizationService,
    private router: Router,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.claimAuthorizationService
      .userHasClaim(this.onyxRequiredClaimOr, next.data.requiredClaims)
      .pipe(
        map(authorized => {
          if (!authorized) {
            return this.router.parseUrl('/unauthorized');
          }
          return true;
        })
      );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.claimAuthorizationService
      .userHasClaim(this.onyxRequiredClaimOr, childRoute.data.requiredClaims)
      .pipe(
        map(authorized => {
          if (!authorized) {
            return this.router.parseUrl('/unauthorized');
          }
          return true;
        })
      );
  }
}
