import { Component, Input } from '@angular/core';
import { ChangelogEntry } from './changelog-entry';

@Component({
  selector: 'onyx-changelog-entry',
  templateUrl: './changelog-entry.component.html',
  styleUrls: ['./changelog-entry.component.scss']
})
export class ChangeLogEntryComponent {
  @Input() entry: ChangelogEntry;

  isReadMore = true;

  showText() {
    this.isReadMore = !this.isReadMore;
  }
}
