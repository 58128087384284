import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '@onyx/shared/common-imports';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationHttpInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private oidcSecurityService: OidcSecurityService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // ignore auth for dev
    if (!this.injector.get(ENVIRONMENT_TOKEN).useAuthentication) {
      return next.handle(req);
    }

    let requestToForward = req;
    if (this.oidcSecurityService !== undefined) {
      //explicitly use configId because it otherwise can't find a config id and crashes the early calls like translation files...
      const token = this.oidcSecurityService.getAccessToken('oidc');
      if (token !== '') {
        requestToForward = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
      }
    }

    return next.handle(requestToForward);
  }
}
