import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Setting, SettingValue } from '../settings';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'onyx-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent {
  public stringValueControl = new FormControl();
  @Input() public setting: Setting;
  @Input() public parentPath = '';
  public get path() {
    const prefix = this.parentPath ? `${this.parentPath}.` : '';
    return `${prefix}${this.setting.key}`;
  }
  public get isBoolean() {
    return (
      Object.getOwnPropertyNames(this.setting).includes('value') &&
      typeof this.setting.value === 'boolean'
    );
  }
  public get isString() {
    return (
      Object.getOwnPropertyNames(this.setting).includes('value') &&
      typeof this.setting.value === 'string'
    );
  }
  public get isNumber() {
    return (
      Object.getOwnPropertyNames(this.setting).includes('value') &&
      typeof this.setting.value === 'number'
    );
  }
  public get hasOptions() {
    return (
      Object.getOwnPropertyNames(this.setting).includes('options') &&
      this.setting.options.length > 0
    );
  }

  public constructor(private settingsService: SettingsService) {}
  public changeValue(value: SettingValue) {
    this.settingsService.setSetting(this.path, value);
  }

  public selectionChanged(event: any) {
    this.changeValue(event.source.value);
  }
}
