import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMaterialModule } from '@onyx/shared/common-imports';
import { BrowserSupportService } from './browser-support.service';
import { OnyxClickOutsideDirective } from './clickoutside.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationService } from './confirmation-dialog/confirmation.service';
import { ConnectionMessageService } from './connection-snack-bar/connection-message.service';
import { ConnectionSnackBarComponent } from './connection-snack-bar/connection-snack-bar.component';
import { FullscreenService } from './fullscreen/fullscreen.service';
import { HighLightPipe } from './highlight-search/highlight-search.pipe';
import { CustomMatPaginatorIntl } from './i18n/custom-mat-paginator-intl';
import { LanguageAttributeDirective } from './i18n/language-attribute.directive';
import { localeProvider } from './i18n/translate-locale.provider';
import { LoadingEllipsisComponent } from './loading-ellipsis/loading-ellipsis.component';
import { LocalStorageService } from './localstorage/local-storage.service';
import { NotifyDialogComponent } from './notify-dialog/notify-dialog.component';
import { ResponsiveDirective } from './responsiveness/responsive.directive';
import { ResponsiveService } from './responsiveness/responsive.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { ValueAsClassDirective } from './value-as-class.directive';

export { ConfirmationResult } from './confirmation-dialog/confirmation-result';
export {
  ConnectionMessageService,
  ConnectionSnackBarComponent,
  ResponsiveService,
  BrowserSupportService,
  NotifyDialogComponent,
  FullscreenService,
  OnyxClickOutsideDirective,
  HighLightPipe,
  CustomMatPaginatorIntl
};
export { ConfirmationDialogComponent, ConfirmationService };
export { LocalStorageService };

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    MatDialogModule,
    TranslateModule
  ],
  providers: [
    ConfirmationService,
    ResponsiveService,
    BrowserSupportService,
    FullscreenService,
    localeProvider
  ],
  declarations: [
    ConnectionSnackBarComponent,
    LoadingEllipsisComponent,
    ValueAsClassDirective,
    ConfirmationDialogComponent,
    ResponsiveDirective,
    NotifyDialogComponent,
    SpinnerComponent,
    LanguageAttributeDirective,
    OnyxClickOutsideDirective,
    HighLightPipe
  ],
  exports: [
    ValueAsClassDirective,
    ResponsiveDirective,
    SpinnerComponent,
    TranslateModule,
    LanguageAttributeDirective,
    OnyxClickOutsideDirective,
    HighLightPipe
  ]
})
export class OnyxCommonModule {}
