import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JaegerInterceptor } from './jaeger.http-interceptor';
import { TracerService } from './tracer.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: JaegerInterceptor
    },
    TracerService
  ]
})
export class SharedLoggingModule {}
