import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_CULTURES } from '@onyx/shared/common';
import { SettingsService } from '@onyx/shared/settings';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsInitiliazerService implements OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(translate: TranslateService, settingsService: SettingsService) {
    translate.setDefaultLang('en');
    translate.addLangs(Object.keys(SUPPORTED_CULTURES));
    translate.use('en');

    settingsService.languageSetting$
      .pipe(takeUntil(this.destroy$))
      .subscribe(language => {
        translate.use(language);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
