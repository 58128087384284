import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { FeatureOverlayComponent } from './feature-overlay/feature-overlay.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FeatureOverlayRef } from './feature-overlay/feature-overlay.ref';

@Injectable({
  providedIn: 'root'
})
export class FeatureOverlayService {
  public constructor(
    private overlay: Overlay,
    private breakpointObserver: BreakpointObserver,
    private injector: Injector
  ) {}

  public openFeaturesOverlay(): FeatureOverlayRef {
    const isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);
    const overlayRef = this.overlay.create({
      positionStrategy: isMobile
        ? this.overlay.position().global().left('5%')
        : this.overlay.position().global().left('20%'),
      width: isMobile ? '90%' : '60%',
      hasBackdrop: true,
      height: '100%'
    });
    const featureOverlayRef = new FeatureOverlayRef(overlayRef);
    const tokens = new WeakMap();
    tokens.set(FeatureOverlayRef, featureOverlayRef);
    const portalInjector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: FeatureOverlayRef,
          useValue: featureOverlayRef
        }
      ]
    });
    const featureOverlayPortal = new ComponentPortal(
      FeatureOverlayComponent,
      null,
      portalInjector
    );
    overlayRef.backdropClick().subscribe(() => {
      featureOverlayRef.close();
    });
    overlayRef.attach(featureOverlayPortal);
    return featureOverlayRef;
  }
}
