<ng-container *onyxFeature="setting.feature">
  <ng-container *ngIf="setting.children">
    <div class="setting-item" [attr.data-cy-setting-key]="setting.key">
      <h3>{{ setting.label | translate }}</h3>
      <onyx-setting
        [parentPath]="path"
        [setting]="childSetting"
        *ngFor="let childSetting of setting.children"
      ></onyx-setting>
    </div>
  </ng-container>
  <div *ngIf="!setting.children">
    <ng-container *ngIf="isBoolean">
      <div
        fxLayout="row"
        class="setting"
        matRipple
        (click)="changeValue(!setting.value)"
      >
        <div data-setting-label fxFlex>
          {{ setting.label | translate }}
        </div>
        <div>
          <mat-slide-toggle
            color="primary"
            [checked]="setting.value"
            data-cy-setting-control
          ></mat-slide-toggle>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isString && hasOptions">
      <mat-form-field>
        <mat-label>{{ setting.label | translate }}</mat-label>
        <mat-select
          [value]="setting.value"
          (selectionChange)="selectionChanged($event)"
          data-cy-setting-control
        >
          <mat-option
            *ngFor="let option of setting.options"
            [value]="option.value"
            [attr.data-cy-value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isString && !hasOptions">
      <mat-form-field>
        <input
          type="search"
          matInput
          autocomplete="off"
          [placeholder]="setting.label"
          [value]="setting.value"
          (change)="changeValue(stringValueControl.value)"
          [formControl]="stringValueControl"
          data-cy-setting-control
        />
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>
