import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CustomComponentLoaderService } from '../custom-component-loader.service';

@Component({
  selector: 'onyx-main-menu-item-hook',
  templateUrl: './main-menu-item-hook.component.html',
  styleUrls: ['./main-menu-item-hook.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuItemHookComponent {
  activeComponentName$: Observable<string>;

  /**
   * This generic component will use the parameter of the URL to create a new DOM element for the custom component.
   * Loading of any related files will be offloaded to the loader.
   * @param route - Angular Activated Route
   * @param loader - the service responsible for loading external modules related to the component
   */
  constructor(
    route: ActivatedRoute,
    private loader: CustomComponentLoaderService
  ) {
    this.activeComponentName$ = route.paramMap.pipe(
      map(pm => pm.get('component')),
      filter(component => !!component),
      mergeMap((component: string) => this.loader.loadMenuItem(component))
    );
  }
}
