import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { SIDENAV_ITEMS, TenantShellComponent } from '@onyx/navigation';
import {
  AuthenticatedGuard,
  AuthorizedGuard,
  AutoLoginComponent,
  Claims,
  DemoLoginComponent,
  SignInComponent,
  UnauthorizedComponent
} from '@onyx/shared/authentication';
import { MainMenuHookRoute } from '@onyx/shared/micro-frontends';
import { environment } from '../environments/environment';
import { NoTenantsComponent } from './no-tenants.component';
import { NotFoundComponent } from './not-found.component';

// only lazy loading unless absolutely necessary!
const APP_ROUTES: Route[] = [
  { path: environment.oidc_postlogin_path, component: SignInComponent },
  { path: 'auto-login', component: AutoLoginComponent },
  {
    path: 'demo-login',
    component: DemoLoginComponent,
    canActivate: [AuthenticatedGuard]
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: 'setup',
    canActivateChild: [AuthenticatedGuard],
    loadChildren: () => import('@onyx/setup').then(m => m.SetupModule)
  },
  {
    path: 'no-tenants',
    canActivateChild: [AuthenticatedGuard],
    component: NoTenantsComponent
  },
  {
    path: 'qr-code',
    redirectTo: '/demo-login?tenant=kma-project',
    pathMatch: 'full'
  },
  {
    path: '',
    component: TenantShellComponent,
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      MainMenuHookRoute,
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            loadChildren: async () =>
              (await import('./dashboard-page/dashboard-page.module'))
                .DashboardPageModule
          }
        ]
      },
      {
        path: 'management',
        loadChildren: () =>
          import('@onyx/management').then(m => m.ManagementModule)
      },
      {
        path: 'system-overview',
        loadChildren: () =>
          import('@onyx/system-overview/feature').then(
            m => m.SystemOverviewFeatureModule
          ),
        canActivate: [AuthorizedGuard],
        data: {
          requiredClaims: [
            Claims.OffboardLocationRead,
            Claims.OffboardSignalRead,
            Claims.OffboardVehicleRead
          ]
        }
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@onyx/shared/settings').then(m => m.SharedSettingsModule)
      },
      {
        path: 'features',
        loadChildren: () =>
          import('@onyx/shared/feature-flags').then(
            m => m.SharedFeatureFlagsModule
          )
      },
      {
        path: 'application-settings',
        loadChildren: () =>
          import('@onyx/application-settings/feature').then(
            m => m.ApplicationSettingsFeatureModule
          )
      },
      {
        path: 'agv',
        loadChildren: () =>
          import('@onyx/agv/feature').then(m => m.AgvFeatureModule),
        canActivate: [AuthorizedGuard],
        data: { requiredClaims: [Claims.OffboardVehicleRead] }
      },
      {
        path: 'transport',
        loadChildren: () =>
          import('@onyx/transport/feature').then(m => m.TransportFeatureModule),
        canActivate: [AuthorizedGuard],
        data: { requiredClaims: [Claims.OffboardTransportRead] }
      },
      {
        path: 'location-management',
        loadChildren: () =>
          import('@onyx/location-management/feature').then(
            m => m.LocationManagementFeatureModule
          ),
        canActivate: [AuthorizedGuard],
        data: { requiredClaims: [Claims.OffboardLocationRead] }
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('@onyx/user-management').then(m => m.UserManagementModule),
        canActivate: [AuthorizedGuard],
        data: { requiredClaims: [Claims.ManageAccount] }
      },
      {
        path: 'signal-management',
        loadChildren: () =>
          import('@onyx/signal-management/feature').then(
            m => m.SignalManagementFeatureModule
          ),
        canActivate: [AuthorizedGuard],
        data: { requiredClaims: [Claims.OffboardSignalRead] }
      },
      {
        path:'job-management',
        loadChildren: () => import('./job-management-page/job-management-page.module').then(m => m.JobManagementPageModule),
        data: { requiredClaims: [Claims.OffboardJobRead] }

      },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
      enableTracing: false // !environment.production
    })
  ],
  providers: [
    {
      provide: SIDENAV_ITEMS,
      useValue: [
        {
          path: 'dashboard',
          description: _('nav.dashboard'),
          iconName: 'nav_dashboard',
          svgIcon: true,
          navTop: true
        },
        // {
        //   path: `system-overview`,
        //   description: _(`nav.systemOverview`),
        //   iconName: 'nav_insights',
        //   svgIcon: true,
        //   navTop: true,
        //   feature: 'system-overview',
        //   claims: [
        //     Claims.OffboardVehicleRead,
        //     Claims.OffboardLocationRead,
        //     Claims.OffboardSignalRead,
        //     Claims.OffboardTransportRead
        //   ]
        // },
        // {
        //   path: `agv`,
        //   description: _(`nav.agv`),
        //   iconName: 'nav_agv',
        //   svgIcon: true,
        //   navTop: true,
        //   feature: 'agv',
        //   claims: [Claims.OffboardVehicleRead]
        // },
        // {
        //   path: `transport`,
        //   description: _('nav.transport'),
        //   iconName: 'nav_transports',
        //   svgIcon: true,
        //   navTop: true,
        //   feature: 'transports',
        //   claims: [Claims.OffboardTransportRead]
        // },
        // {
        //   path: 'location-management',
        //   description: _('nav.management'),
        //   iconName: 'nav_management',
        //   svgIcon: true,
        //   navTop: true,
        //   claims: [Claims.OffboardLocationRead, Claims.OffboardSignalRead],
        //   children: [
        //     {
        //       path: `location-management`,
        //       description: _('nav.location-management'),
        //       iconName: 'nav_locations',
        //       svgIcon: true,
        //       navTop: false,
        //       feature: 'location-management',
        //       claims: [Claims.OffboardLocationRead]
        //     },
        //     {
        //       path: `signal-management`,
        //       description: _('nav.signal-management'),
        //       iconName: 'nav_signals',
        //       svgIcon: true,
        //       navTop: false,
        //       feature: 'signal-management',
        //       claims: [Claims.OffboardSignalRead]
        //     },
        //     {
        //       path:'job-management',
        //       description: _('nav.job-management'),
        //       iconName: 'nav_signals',
        //       svgIcon: true,
        //       navTop: false,
        //       feature: 'job-management',
        //       claims:[Claims.OffboardJobRead]
        //     }
        //   ]
        // }
      ]
    }
  ],
  exports: [RouterModule]
})
export class AppRoutesModule {}
