<mat-sidenav-container class="mat-container">
  <mat-sidenav-content>
    <div class="inner-container">
      <div class="settings-experimental" (click)="openFeaturesOverlay()">
        <mat-icon svgIcon="icon_experimental"></mat-icon>
        <span>{{ 'settings.experimentalFeatures' | translate }}</span>
      </div>
      <h1>{{ 'settings.title' | translate }}</h1>
      <onyx-setting
        *ngFor="let setting of settings$ | async"
        [setting]="setting"
      ></onyx-setting>
      <div class="setting-links">
        <div
          class="setting-link"
          [class.disabled]="(canAccessUserManagement$ | async) === false"
          [routerLink]="
            (canAccessUserManagement$ | async) === true
              ? ['../user-management']
              : null
          "
        >
          <mat-icon svgIcon="icon_users"></mat-icon>
          {{ 'settings.userManagement' | translate }}
        </div>
      </div>
      <div class="settings-changelog" (click)="sidenav.toggle()">
        <span>{{ 'settings.changelog.toggle' | translate }}</span>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    #sidenav
    [opened]="(isHandset$ | async) === false"
    class="mat-sidenav"
    mode="side"
    position="end"
  >
    <div class="mat-sidenav-inner">
      <mat-icon
        (click)="sidenav.toggle()"
        class="close"
        svgIcon="icon_arrow_line"
      ></mat-icon>
      <onyx-changelog></onyx-changelog>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
